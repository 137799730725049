import { FC } from "react";
import { Navigate } from "react-router-dom";

export interface IProtectedRoute {
  children: any;
}

export const ProtectedRoute: FC<IProtectedRoute> = ({ children }) => {
  const token = sessionStorage.getItem("JWT_TOKEN");

  if (!token) {
    return <Navigate to="/Sign-in" replace />;
  }
  return children;
};
