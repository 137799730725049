import axios, { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAuth } from "./types";

export const fetchAuth = createAsyncThunk(
  "auth/fetchAuth",
  async (body: IAuth, thunkAPI) => {
    try {
      const response = await axios.post<IAuth>(
        `https://back.hunt4memories.com/api/user/login`,
        body
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Login failed"
      );
    }
  }
);
