import { Select, SelectProps } from "antd";
import styles from "./MultiTextSelect.module.scss";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { getTimelineIdToken } from "../../hooks/localstorage";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchGetParticipants } from "../../store/api/fetchGetParticipants";

interface IMultiTextSelect {
  label?: string;
  value: any;
  setValue: Dispatch<SetStateAction<string[]>>;
  sx: {};
  centerMode?: any;
}

export const MultiTextSelect: React.FC<IMultiTextSelect> = ({
  label,
  value,
  setValue,
  sx,
  centerMode,
}) => {
  const dispatch = useAppDispatch();
  const id = getTimelineIdToken();

  const gridTemplateColumns: any = centerMode ? null : "180px auto";

  const { data } = useAppSelector((state) => state.getParticipantsReducer);

  useEffect(() => {
    dispatch(fetchGetParticipants(id));
  }, [dispatch]);

  const handleChange = (value: string[]) => {
    setValue(value);
  };

  return (
    <div
      className={styles.wrapper}
      style={{
        display: centerMode ? "block" : "grid",
        gridTemplateColumns,
      }}
    >
      <label htmlFor={label}>{label}</label>
      <Select
        mode="tags"
        onChange={handleChange}
        tokenSeparators={[","]}
        options={data}
        value={value}
        className="my-select"
        style={sx}
      />
    </div>
  );
};
