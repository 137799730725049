import styles from "./SignupBar.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, TextField } from "../..";
import { TailSpin } from "react-loader-spinner";
import { fetchSignUp } from "../../../store/api/fetchSignUp";
import { UploadImages } from "../../../pages/CreateTimeLine/ui/UploadImages";

export const SignupBar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useAppSelector((state) => state.authReducer);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [image, setImage] = useState<string | null>(null);

  const handlerClick = () => {
    dispatch(fetchSignUp({ name, email, phone, password, file: image }));
  };

  useEffect(() => {
    const token = sessionStorage.getItem("JWT_TOKEN");
    if (token) {
      navigate("/Create-timeline");
    }
  }, [data, error]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <Link to="/Sign-in">Log in</Link>
        <Link to="/Sign-up">Sign up</Link>
      </div>
      <div className={styles.body}>
        <TextField
          setValue={setName}
          value={name}
          type="text"
          label="Name:"
          sx={{ width: "80%" }}
        />
        <TextField
          setValue={setEmail}
          value={email}
          type="text"
          label="Email:"
          sx={{ marginTop: "30px", width: "80%" }}
        />
        <TextField
          setValue={setPhone}
          value={phone}
          type="text"
          label="Phone:"
          useMask={true}
          sx={{ marginTop: "30px", width: "80%" }}
        />
        <TextField
          setValue={setPassword}
          value={password}
          type="password"
          label="Password:"
          sx={{ marginTop: "30px", width: "80%" }}
        />
        <div className={styles.error}>
          <span>{error && error}</span>
        </div>
        <div className={styles.upload}>
          <UploadImages setImage={setImage} image={image} />
          {/* <UploadImages
            setImage={setImage}
            image={image}
            preview={image}
            loading={isLoad}
          /> */}
        </div>
      </div>
      <div className={styles.footer}>
        {isLoading ? (
          <div className={styles.loader}>
            <TailSpin
              height="30"
              width="30"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              visible={true}
            />
          </div>
        ) : (
          <Button
            action={handlerClick}
            title="Sign up"
            sx={{ textAlign: "right" }}
          />
        )}
      </div>
    </div>
  );
};
