import styles from "./Carousel.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Dispatch, FC, SetStateAction } from "react";
import arrow from "../../assets/icons/arrow-down.svg";

interface ICarousel {
  picture: Array<File | { [key: string]: any }>;
  setPicture: Dispatch<SetStateAction<Array<File | { [key: string]: any }>>>;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setAdditionalPicture: Dispatch<
    SetStateAction<Array<File | { [key: string]: any }>>
  >;
  setGetPhoto: (value: {}) => void;
}

export const Carousel: FC<ICarousel> = ({
  picture,
  setOpenPopup,
  setGetPhoto,
  setPicture,
  setAdditionalPicture,
}) => {

  function CustomPrevArrow(props: any) {
    const { onClick } = props;
    return (
      <img
        className={styles.arrowLeft}
        onClick={onClick}
        src={arrow}
        alt="arrow"
      />
    );
  }

  function CustomNextArrow(props: any) {
    const { onClick } = props;

    return (
      <img
        className={styles.arrowRight}
        onClick={onClick}
        src={arrow}
        alt="arrow"
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const openPhoto = (id: any) => {
    setOpenPopup(true);
    setGetPhoto(id);
  };

  const deletePicture = (index: number) => {
    const newPicture = [...picture];
    const item = newPicture[index];
    if (typeof item === "object" && "path" in item) {
      URL.revokeObjectURL(item.path);
    }
    newPicture.splice(index, 1);

    if (newPicture.length === 0) {
      alert("You cannot remove the last element from a list");
    } else {
      setPicture(newPicture);
      const filteredPicture = newPicture.filter(
        (item) => Object.prototype.toString.call(item) !== "[object File]"
      );
      setAdditionalPicture(filteredPicture);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Slider {...settings}>
        {picture?.map((item: any, index) =>
          item?.type === "video/mp4" ||
          item?.path?.includes("mp4") ||
          item?.path?.includes("MP4") ||
          item?.path?.includes("mov") ||
          item?.path?.includes("MOV") ||
          item?.path?.includes("mkv") ||
          item?.path?.includes("MKV") ? (
            <>
              {item?.path ? (
                <div className={styles.el} key={index}>
                  <video onClick={() => openPhoto(item)}>
                    <source
                      src={`https://storage.googleapis.com/bucket_for_h4m/${item.path}`}
                      type="video/mp4"
                    />
                  </video>
                  <b onClick={() => deletePicture(index)}>x</b>
                </div>
              ) : (
                <div className={styles.el} key={index}>
                  <video>
                    <source src={URL.createObjectURL(item)} type="video/mp4" />
                  </video>
                  <span>metadata will display after confirmation</span>
                </div>
              )}
            </>
          ) : (
            <>
              {item?.path ? (
                <div className={styles.el} key={index}>
                  <img
                    src={`https://storage.googleapis.com/bucket_for_h4m/${item?.path}`}
                    onClick={() => openPhoto(item)}
                  />
                  <b onClick={() => deletePicture(index)}>x</b>
                </div>
              ) : (
                <div className={styles.el} key={index}>
                  <img src={URL.createObjectURL(item)} />
                  <span>metadata will display after confirmation</span>
                </div>
              )}
            </>
          )
        )}
      </Slider>
    </div>
  );
};
