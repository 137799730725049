import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IComment } from "./type";

export const fetchComments = createAsyncThunk(
  "comments/fetchComments",
  async ({ id, ...body }: { id: string | null } & IComment, thunkAPI) => {
    try {
      const response = await axiosConfig.post<IComment>(`timeline/addComment/${id}`, body);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "add comment failed"
      );
    }
  }
);
