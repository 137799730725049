import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IMemoriesName } from "./types";

export const fetchGetMemories = createAsyncThunk(
  "getMemories/fetchGetMemories",
  async (id: string | null, thunkAPI) => {
    try {
      const response = await axiosConfig.get<string>(
        `/timeline/${id}/getMemories`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed"
      );
    }
  }
);

export const fetchGetMemoriesName = createAsyncThunk(
  "getMemories/fetchGetMemoriesName",
  async (body: IMemoriesName, thunkAPI) => {
    try {
      const response = await axiosConfig.get<string>(
        `/timeline/${body.id}/getMemories/${body.name}`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed"
      );
    }
  }
);
