import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IFiles } from "./types";

export const fetchUpdateFilesMemories = createAsyncThunk<any, [FormData, string], { rejectValue: string }>(
  "updateFilesMemories/fetchUpdateFilesMemories",
  async ([formData, id]: [FormData, string | null], thunkAPI) => {
    try {

      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      const response = await axiosConfig.post<IFiles>(`/memory/updateFiles/${id}`, formData, config);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed upload files"
      );
    }
  }
);

export const fetchUpdateFilesMemoriess = createAsyncThunk<IFiles, { formData: FormData, ids: string }, { rejectValue: string }>(
  "updateFilesMemories/fetchUpdateFilesMemoriess",
  async ({ formData, ids }, thunkAPI) => {
    try {
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      const response = await axiosConfig.post<IFiles>(`/memory/updateFiles/${ids}`, formData, config);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed upload files"
      );
    }
  }
);


