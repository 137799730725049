import { Backgrounds, NavigateBar } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import { TimeLineContainer } from "./ui";
import Download from "../../assets/icons/download.png";

export const CreateTimeLine = () => {
  return (
    <Layout>
      <NavigateBar />
      <Backgrounds background={Background} logo={Download}>
        <TimeLineContainer />
      </Backgrounds>
    </Layout>
  );
};
