import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMemory } from "./types";
import { fetchGetMemories, fetchGetMemoriesName } from "../../../api/fetchGetMemories";

interface UserState {
  data: IMemory[];
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: [],
  isLoading: false,
  error: "",
};

export const getMemoriesReducer = createSlice({
  name: "getMemories",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGetMemories.fulfilled.type]: (
      state,
      action: PayloadAction<IMemory[]>
    ) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchGetMemories.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchGetMemories.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [fetchGetMemoriesName.fulfilled.type]: (
      state,
      action: PayloadAction<IMemory[]>
    ) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchGetMemoriesName.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchGetMemoriesName.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default getMemoriesReducer.reducer;
