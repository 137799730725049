import styles from "./PopupGridPicture.module.scss";
import { Dispatch, FC, SetStateAction } from "react";

interface IPopupGridPicture {
  setOpenGridPicture: any;
  data?: any;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
  handleSelectPicture?: any;
}

export const PopupGridPicture: FC<IPopupGridPicture> = ({
  setOpenGridPicture,
  data,
  setOpenPopup,
  setGetPhoto,
  handleSelectPicture,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      setOpenGridPicture(false);
    }
  };

  const openPhoto = (id: any) => {
    setOpenPopup(true);
    setGetPhoto(id);
  };

  return (
    <div className={styles.wrapper} onClick={handleClick}>
      <div className={styles.container}>
        {/* {data?.map((item: any) => (
          <div key={item?.id} className={styles.element}>
            <img
              onClick={() => openPhoto(item)}
              className={item?.metadata ? styles.images : ""}
              src={`https://storage.googleapis.com/bucket_for_h4m/${item?.path}`}
            />
            <span onClick={() => handleSelectPicture(item)}>+</span>
          </div>
        ))} */}
        {data?.map((item: any) => {
          const extension = item.path.split(".").pop()?.toLowerCase();
          if (
            extension === "mp4" ||
            extension === "MP4" ||
            extension === "mov" ||
            extension === "MOV" ||
            extension === "mkv" ||
            extension === "MKV"
          ) {
            return (
              <div key={item?.id} className={styles.element}>
                <video
                  onClick={() => openPhoto(item)}
                  className={item?.metadata ? styles.images : ""}
                >
                  <source
                    src={`https://storage.googleapis.com/bucket_for_h4m/${item.path}`}
                    type="video/mp4"
                  />
                </video>
                <span onClick={() => handleSelectPicture(item)}>+</span>
              </div>
            );
          } else {
            return (
              <div key={item?.id} className={styles.element}>
                <img
                  onClick={() => openPhoto(item)}
                  className={item?.metadata ? styles.images : ""}
                  src={`https://storage.googleapis.com/bucket_for_h4m/${item.path}`}
                />
                <span onClick={() => handleSelectPicture(item)}>+</span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
