import styles from "./SpecialInput.module.scss";
import { useState, useEffect, Dispatch, FC, SetStateAction } from "react";
import MaskedInput from "react-input-mask";

interface ISpecialInput {
  label: string;
  type: string;
  sx?: {};
  value: string | undefined;
  setValue: Dispatch<SetStateAction<string>>;
  useMask?: boolean;
}

export const SpecialInput: FC<ISpecialInput> = ({
  label,
  type,
  sx,
  value,
  setValue,
  useMask = false,
}) => {
  const [cleanedValue, setCleanedValue] = useState(value);

  useEffect(() => {
    setCleanedValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const cleanedInputValue = inputValue.replace(/[^a-zA-Zа-яА-ЯёЁא-ת\s0-9-'"]/g, "");
    setValue(cleanedInputValue);
    setCleanedValue(cleanedInputValue);
  };

  const inputElement = useMask ? (
    <MaskedInput
      mask="+972999999999"
      value={cleanedValue}
      onChange={handleChange}
      id={label}
      type={type}
      style={sx}
    />
  ) : (
    <input
      value={cleanedValue}
      onChange={handleChange}
      id={label}
      type={type}
      style={sx}
    />
  );

  return (
    <div className={styles.wrapper}>
      <label htmlFor={label}>{label}</label>
      {inputElement}
    </div>
  );
};
