import { FC, useState } from "react";
import styles from "./CategoryBar.module.scss";

interface ICategoryBar {
  categories: string[];
  setCategories: (categories: string[]) => void;
}

export const CategoryBar: FC<ICategoryBar> = ({
  categories,
  setCategories,
}) => {
  const [newCategory, setNewCategory] = useState<string>("");

  const handlerAdd = () => {
    if (newCategory.trim() !== "") {
      setCategories([...categories, newCategory]);
      setNewCategory("");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCategory(event.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <label>Add category</label>
      <div className={styles.container}>
        <div className={styles.preview}>
          {categories.map((item: string, index: number) => (
            <div className={styles.item} key={index}>
              <p onClick={() => {}}>{item}</p>
            </div>
          ))}
        </div>
        <div className={styles.panel}>
          <input
            className={styles.updateField}
            onChange={handleChange}
            type="text"
            value={newCategory}
          />
          <p className={styles.plus} onClick={handlerAdd}>
            +
          </p>
        </div>
      </div>
    </div>
  );
};
