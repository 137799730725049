import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCreatePanding } from "../../../api/fetchCreatePanding";

export interface IResult {
  id: null;
}

interface UserState {
  updatePanding: IResult;
  Loading: boolean;
  errors: null;
}

const initialState: UserState = {
  updatePanding: {
    id: null
  },
  Loading: false,
  errors: null,
};

export const createPandingReducer = createSlice({
  name: "createPanding",
  initialState,
  reducers: {
    resetCreatePanding: (state) => {
      state.updatePanding = initialState.updatePanding;
    },
  },
  extraReducers: {
    [fetchCreatePanding.fulfilled.type]: (
      state,
      action: PayloadAction<IResult>
    ) => {
      state.Loading = false;
      state.errors = null;
      state.updatePanding = action.payload;
    },
    [fetchCreatePanding.pending.type]: (state) => {
      state.Loading = true;
    },
    [fetchCreatePanding.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.Loading = false;
      state.errors = action.payload;
    },
  },
});

export const { resetCreatePanding } = createPandingReducer.actions;

export default createPandingReducer.reducer;
