import { Backgrounds, NavigateBar } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";

import Download from "../../assets/icons/download.png";
import { TimeLineContainers } from "./TimeLineContainers";
import { useState } from "react";
import { PopupTimeline } from "./ui/PopupTimeline";
import { PopupAddition } from "./ui/PopupAddition";

export const SettingsTimeLine = () => {
  const [users, setUsers] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openAddition, setOpenAddition] = useState(false);

  return (
    <Layout>
      <NavigateBar />
      <Backgrounds background={Background} logo={Download}>
        <TimeLineContainers setUsers={setUsers} openPopup={openPopup} setOpenPopup={setOpenPopup} setOpenAddition={setOpenAddition} openAddition={openAddition} />
        {openPopup && <PopupTimeline users={users} setOpenPopup={setOpenPopup} />}
        {openAddition && <PopupAddition setOpenAddition={setOpenAddition} />}
      </Backgrounds>
    </Layout>
  );
};
