import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  DateField,
  FieldDescription,
  MultiTextSelect,
  SelectText,
  SmallRadio,
  TextFieldLine,
  UploadImages,
} from "../../../../components";
import styles from "./EditContainer.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import moment from "moment";
import { fetchSingleMemory } from "../../../../store/api/fetchSingleMemory";
import {
  getMemoryIdToken,
  getTimelineIdToken,
} from "../../../../hooks/localstorage";
import { Carousel } from "../../../../components/Carousel";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  fetchUpdateFilesMemories,
  fetchUpdateFilesMemoriess,
} from "../../../../store/api/fetchUpdateFilesMemories";
import { fetchUpdatePanding } from "../../../../store/api/fetchUpdatePanding";
import { resetUpdatePanding } from "../../../../store/feature/panding/updatePanding";
import { resetUpdateMemoryFiles } from "../../../../store/feature/memory/updateFilesMemories";
import { fetchCreatePanding } from "../../../../store/api/fetchCreatePanding";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { resetCreatePanding } from "../../../../store/feature/panding/createPanding";
import { resetUpdateMemoryFiless } from "../../../../store/feature/memory/updateFilesMemoriess";
import Moment from "react-moment";
import { DateFieldCustom } from "../DateFieldCustom";

interface IEditContainer {
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
}

export const EditContainer: FC<IEditContainer> = ({
  setOpenPopup,
  setGetPhoto,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const id = getMemoryIdToken();
  const timeline = getTimelineIdToken();

  const [cv, setCv] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [date, setDate] = useState<any>("");
  const [location, setLocation] = useState<string>("");
  const [participants, setParticipants] = useState<any>([]);
  const [description, setDescription] = useState<string>("");
  const [files, setFiles] = useState<any>(null);
  const [privacy, setPrivacy] = useState<string>("");
  const [bulkPageFiles, setBulkPageFiles] = useState<any>([]);
  const [memoryFiles, setMemoryFiles] = useState<any>([]);
  const [visualCarousel, setVisualCarousel] = useState<any>([]);
  const [additionalPicture, setAdditionalPicture] = useState<any>([]);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [hiddenFieldOne, setHiddenFieldOne] = useState(false);
  const [hiddenFieldTwo, setHiddenFieldTwo] = useState(false);
  const [newDate, setNewDate] = useState("");

  const { data, isLoading } = useAppSelector(
    (state) => state.singlePandingMemoryReducer
  );
  const { update, Loading, erro } = useAppSelector(
    (state) => state.updatePandingReducer
  );
  const { responseData, Load, err } = useAppSelector(
    (state) => state.updateFilesMemoriesReducer
  );
  const { updatePanding } = useAppSelector(
    (state) => state.createPandingReducer
  );
  const resDatass = useSelector(
    (state: RootState) => state.updateFilesMemoriessReducer
  );
  const singleMemoryData = useSelector(
    (state: RootState) => state.singleMemoryReducer
  );

  useEffect(() => {
    if (files != null) {
      setVisualCarousel([...memoryFiles, ...bulkPageFiles, ...files]);
    }
  }, [files]);

  useEffect(() => {
    if (update === "OK" && files === null) {
      dispatch(resetUpdatePanding());
      navigate("/Timeline");
      dispatch(resetUpdatePanding());
    } else if (update === "OK" && files !== null && !isRequestSent) {
      // const formData = new FormData();
      // formData.append("files", files);
      const formData = new FormData();
      files.forEach((file: string | Blob) => {
        formData.append("files", file);
      });
      dispatch(fetchUpdateFilesMemories([formData, id || ""]));
      setIsRequestSent(true);
    } else if (responseData === "OK" && isRequestSent) {
      dispatch(resetUpdatePanding());
      dispatch(resetUpdateMemoryFiles());
      navigate("/Timeline");
    }
  }, [update, responseData, files, isRequestSent, navigate, dispatch, id]);

  useEffect(() => {
    if (updatePanding.id !== null && files === null) {
      dispatch(resetCreatePanding());
      navigate("/Timeline");
    } else if (updatePanding.id !== null && files !== null && !isRequestSent) {
      const formData = new FormData();
      files.forEach((file: string | Blob) => {
        formData.append("files", file);
      });
      dispatch(
        fetchUpdateFilesMemoriess({ formData, ids: updatePanding.id || "" })
      );
      setIsRequestSent(true);
    } else if (resDatass.responseData === "OK" && isRequestSent) {
      dispatch(resetCreatePanding());
      dispatch(resetUpdateMemoryFiless());
      navigate("/Timeline");
    }
  }, [
    updatePanding.id,
    resDatass.responseData,
    files,
    isRequestSent,
    navigate,
    dispatch,
    id,
  ]);
  const handlerUpdate = () => {
    const id = data?.memoryId;
    const type = data?.action;
    if (type === "update") {
      dispatch(
        fetchUpdatePanding({
          action: data?.action,
          actionId: data?.actionId,
          author: data?.author,
          bulkPageFiles:
            data?.bulkPageFiles?.length > 0
              ? bulkPageFiles
              : data?.bulkPageFiles,
          cv: data?.cv?.length > 0 ? cv : data.cv,
          // date: newDate !== "" ? newDate : date ? date : data?.date,
          date: hiddenFieldOne
            ? singleMemoryData?.data?.date
            : hiddenFieldTwo
            ? date
            : date,
          description:
            data?.description?.length > 0 ? description : data.description,
          guestName: data?.guestName,
          location: data?.location?.length > 0 ? location : data?.location,
          memoryFiles:
            data?.memoryFiles.length > 0 ? memoryFiles : data?.memoryFiles,
          memoryId: data?.memoryId,
          name: data?.name?.length > 0 ? name : data?.name,
          participants:
            data?.participants?.length > 0 ? participants : data?.participants,
          privacy: data?.privacy?.length > 0 ? privacy : data?.privacy,
          id: id,
        })
      );
    } else {
      dispatch(
        fetchCreatePanding({
          action: data?.action,
          actionId: data?.actionId,
          author: data?.author,
          bulkPageFiles:
            data?.bulkPageFiles?.length > 0
              ? bulkPageFiles
              : data?.bulkPageFiles,
          cv: data?.cv?.length > 0 ? cv : data.cv,
          date: date ? date : data.date,
          description:
            data?.description?.length > 0 ? description : data.description,
          guestName: data?.guestName,
          location: data?.location?.length > 0 ? location : data?.location,
          memoryFiles:
            data?.memoryFiles.length > 0 ? memoryFiles : data?.memoryFiles,
          memoryId: data?.memoryId,
          name: data?.name?.length > 0 ? name : data?.name,
          participants:
            data?.participants?.length > 0 ? participants : data?.participants,
          privacy: data?.privacy?.length > 0 ? privacy : data?.privacy,
          timeline: timeline,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(fetchSingleMemory(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (data) {
      const {
        cv,
        name,
        date,
        location,
        description,
        participants,
        privacy,
        bulkPageFiles,
        memoryFiles,
      } = data;
      setCv(cv);
      setName(name);
      setLocation(location);
      setParticipants(participants);
      setDescription(description);
      setPrivacy(privacy);

      const formattedDate = moment.utc(date).format("YYYY-MM-DD");
      setDate(formattedDate);

      const mergedPicture = [...bulkPageFiles, ...memoryFiles];
      setVisualCarousel(mergedPicture);
      setBulkPageFiles(bulkPageFiles);
      setMemoryFiles(memoryFiles);
    }
  }, [data]);

  const getDateTwo = (item: any) => {
    setHiddenFieldTwo(true);
    setNewDate(item);
  };

  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        {(isLoading || Loading || Load) && (
          <TailSpin
            height="50"
            width="50"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        )}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.texts}>
          <div className={styles.column}>
            <SelectText
              label="Master headlines(CV) :"
              value={cv}
              setValue={setCv}
              sx={{ width: "300px" }}
            />
            <TextFieldLine
              label="Name :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
            {data?.action === "update" ? (
              <div className={styles.wrapperUpdate}>
                <p>Date :</p>
                <div className={styles.summaryWrapper}>
                  {!hiddenFieldOne && (
                    <div className={styles.updateColumnsOne}>
                      <span>
                        {!hiddenFieldOne && (
                          <b onClick={() => setHiddenFieldOne(true)}>
                            {" "}
                            &times;{" "}
                          </b>
                        )}
                      </span>
                      <DateFieldCustom
                        label="Date :"
                        width="110px"
                        value={date}
                        setValue={setDate}
                      />
                    </div>
                  )}
                  {!hiddenFieldTwo && (
                    <div className={styles.updateColumnsTwo}>
                      <span>
                        {!hiddenFieldTwo && (
                          <b onClick={() => setHiddenFieldTwo(true)}>
                            {" "}
                            &times;{" "}
                          </b>
                        )}
                      </span>
                      <span>
                        <Moment format="YYYY-MM-DD">
                          {singleMemoryData?.data?.date}
                        </Moment>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <DateField
                label="Date :"
                width="300px"
                value={date}
                setValue={setDate}
              />
            )}

            <TextFieldLine
              label="Location :"
              type="text"
              value={location}
              setValue={setLocation}
              sx={{ width: "300px" }}
            />
            <MultiTextSelect
              label="Participants :"
              value={participants}
              setValue={setParticipants}
              sx={{ width: "300px" }}
            />
            <FieldDescription
              label="Description :"
              value={description}
              setValue={setDescription}
              sx={{ width: "300px" }}
            />
          </div>
          <div className={styles.radio}>
            <SmallRadio setPrivacy={setPrivacy} privacy={privacy} />
          </div>
          <div className={styles.carousel}>
            <p>Additional media :</p>
            <Carousel
              setOpenPopup={setOpenPopup}
              setGetPhoto={setGetPhoto}
              picture={visualCarousel}
              setPicture={setVisualCarousel}
              setAdditionalPicture={setAdditionalPicture}
            />
          </div>
        </div>
        <div className={styles.texts}>
          <UploadImages
            image={files}
            setImage={setFiles}
            preview={bulkPageFiles}
          />
        </div>
      </div>
      <span
        style={{
          display: "flex",
          justifyContent: "right",
          color: "red",
          fontSize: "13px",
          height: "20px",
        }}
      >
        {erro || err}
      </span>
      {data?.action === "update" ? (
        <>
          {hiddenFieldOne === false && hiddenFieldTwo === false ? (
            <Button
              sx={{ display: "flex", justifyContent: "right", color: "red" }}
              title="Update"
              action={() => alert("You must select at least one and dates")}
            />
          ) : (
            <Button
              sx={{ display: "flex", justifyContent: "right" }}
              title="Update"
              action={handlerUpdate}
            />
          )}
        </>
      ) : (
        <Button
          sx={{ display: "flex", justifyContent: "right" }}
          title="Update"
          action={handlerUpdate}
        />
      )}
    </div>
  );
};
