import { Backgrounds, NavigateBar, PopupPicture } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { UploadContainer } from "./ui/UploadContainer";
import { FC, useState, useEffect } from "react";
import { ResultContainer } from "./ui/ResultContainer";
import { MemoryCreatePopup } from "./ui/MemoryCreatePopup";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

export const CreateMemory:FC = () => {
  const [display, setDisplay] = useState(1);
  const newMemory = useSelector((state: RootState) => state.createMemoriesReducer);
  const [closeProgressBar, setCloseProgressBar] = useState(false);
  const [step, setStep] = useState(1);

  const [openPopup, setOpenPopup] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});

  return (
    <Layout>
      <NavigateBar />
      <Backgrounds background={Background} logo={Download}>
        {display === 1 ? (
          <UploadContainer setDisplay={setDisplay} setClose={setCloseProgressBar} data={newMemory?.memory} />
        ) : display === 2 ? (
          <>
            {!closeProgressBar && <MemoryCreatePopup setStep={setStep} step={step} setClose={setCloseProgressBar} />}
            <ResultContainer setClose={setDisplay} data={newMemory?.memory} error={newMemory.errors} loading={newMemory.Loading} response={newMemory.result} setOpenPopup={setOpenPopup} setGetPhoto={setGetPhoto} />
            {openPopup && <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} />}
          </>
        ) : null}
      </Backgrounds>
    </Layout>
  );
};
