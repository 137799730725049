import styles from "./Upload.module.scss";
import { Backgrounds, NavigateBar } from "../../components";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { Layout } from "../../utils/Layout";
import { MultiUpload } from "./ui/MultiUpload";
import { useEffect, useMemo, useState } from "react";
import { fetchUpdateFiles } from "../../store/api/fetchUpdateFiles";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  getTimelineIdToken,
  setTimelineIdToken,
  setTimelineNameToken,
} from "../../hooks/localstorage";
import { useNavigate } from "react-router-dom";
import { resetFiles } from "../../store/feature/scattered/updateFiles";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchUserTimelines } from "../../store/api/fetchUserTimelines";
import { resetUpload } from "../../store/feature/timeline/createTimeline";

export const Upload = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState([]);
  const id = getTimelineIdToken();
  const { data, isLoading, error } = useAppSelector((state) => state.updateFilesReducer);
  const { upload } = useAppSelector((state) => state.createTimelineReducer);
  const timelines = useSelector((state: RootState) => state.userTimelinesReducer);

  const lastTimeline = useMemo(() => {
    const dataArray = Array.isArray(timelines?.data) ? timelines.data : [];
    return dataArray.slice(-1)[0];
  }, [upload && timelines?.data]);

  useEffect(() => {
    if (lastTimeline) {
      const timelineId = lastTimeline?._id;
      const timelineName = lastTimeline?.name;
      setTimelineIdToken(timelineId);
      setTimelineNameToken(timelineName);
    }
  }, [lastTimeline]);

  useEffect(() => {
    if (lastTimeline) {
      const timelineId = lastTimeline?._id;
      const timelineName = lastTimeline?.name;
      setTimelineIdToken(timelineId);
      setTimelineNameToken(timelineName);
    }
  }, [lastTimeline]);

  useEffect(() => {
    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      dispatch(fetchUpdateFiles([formData, id || ""]));
    }
  }, [files]);

  useEffect(() => {
    dispatch(fetchUserTimelines());
  }, [dispatch]);

  useEffect(() => {
    if (data === "OK") {
      navigate("/scattered");
      dispatch(resetFiles());
      dispatch(resetUpload());
    }
  }, [data, dispatch, navigate]);

  return (
    <Layout>
      <NavigateBar />
      <Backgrounds background={Background} logo={Download}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <h1>Upload Media</h1>
            {isLoading ? (
              <TailSpin
                height="50"
                width="50"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
              />
            ) : (
              <div className={styles.block}>
                <MultiUpload setImage={setFiles} />
              </div>
            )}

            {error ? (
              <p className={styles.error}>{error} </p>
            ) : (
              "Add to scattered picture"
            )}
          </div>
        </div>
      </Backgrounds>
    </Layout>
  );
};
