import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./feature/authSlice";
import signUpReducer from "./feature/signUpSlice";
import singleTimelineReducer from "./feature/timeline/single-timeline/singleTimeline";
import createTimelineReducer from "./feature/timeline/createTimeline";
import userTimelinesReducer from "./feature/timeline/allTimeline";
import getMemoriesReducer from "./feature/memory/getMemories";
import filesReducer from "./feature/scattered";
import singleMemoryReducer from "./feature/memory/singleMemory";
import updateMemoriesReducer from "./feature/memory/updateMemories";
import updateFilesReducer from "./feature/scattered/updateFiles";
import createMemoriesReducer from "./feature/memory/createMemories";
import updateFilesMemoriesReducer from "./feature/memory/updateFilesMemories";
import addParticipantReducer from "./feature/participants/addParticipant";
import getParticipantsReducer from "./feature/participants/getParticipants";
import updateFilesMemoriessReducer from "./feature/memory/updateFilesMemoriess";
import fileMetadataReducer from "./feature/scattered/addComment";
import createDocumentReducer from "./feature/document/createDocument";
import updateFilesDocumentReducer from "./feature/document/updateFilesDocument";
import getDocumentReducer from "./feature/document/getDocument";
import singleDocumentReducer from "./feature/document/singleDocument";
import updateDocumentReducer from "./feature/document/updateDocument";
import updateTimelineReducer from "./feature/timeline/updateTimeline";
import updatePhotoTimelineReducer from "./feature/timeline/updatePhotoTimeline";
import updateParticipantReducer from "./feature/participants/updateParticipant";
import changeRoleToAdminReducer from "./feature/admin/changeRoleToAdmin";
import singleTimeNonAuthReducer from "./feature/nonAuth/fetchSingleTimeNonAuth";
import filesNonAuthReducer from "./feature/nonAuth/filesNonAuth";
import updateMemoGuestReducer from "./feature/memory/updateMemoGuest";
import updateFilesNonAuthReducer from "./feature/nonAuth/updateFilesNonAuth";
import singlePandingMemoryReducer from "./feature/panding/singlePandingMemory";
import updatePandingReducer from "./feature/panding/updatePanding";
import filterHubReducer from "./feature/filters/filterHub";
import commentsReducer from "./feature/comments";
import deletePandingReducer from "./feature/panding/deletePanding";
import getCategoriesReducer from "./feature/nonAuth/getCategories";
import deleteMemoriesReducer from "./feature/memory/deleteMemories";
import deleteTimelineReducer from "./feature/timeline/deleteTimeline";
import updateMemoriesForSignupReducer from "./feature/memory/updateMemoriesForSignup";
import createPandingReducer from "./feature/panding/createPanding";
import getParticipantDataReducer from "./feature/getParticipantData";

const rootReducer = combineReducers({
  authReducer,
  signUpReducer,
  singleTimelineReducer,
  createTimelineReducer,
  userTimelinesReducer,
  getMemoriesReducer,
  filesReducer,
  singleMemoryReducer,
  updateMemoriesReducer,
  updateFilesReducer,
  createMemoriesReducer,
  updateFilesMemoriesReducer,
  addParticipantReducer,
  getParticipantsReducer,
  updateFilesMemoriessReducer,
  fileMetadataReducer,
  createDocumentReducer,
  updateFilesDocumentReducer,
  getDocumentReducer,
  singleDocumentReducer,
  updateDocumentReducer,
  updateTimelineReducer,
  updatePhotoTimelineReducer,
  updateParticipantReducer,
  changeRoleToAdminReducer,
  singleTimeNonAuthReducer,
  filesNonAuthReducer,
  updateMemoGuestReducer,
  updateFilesNonAuthReducer,
  singlePandingMemoryReducer,
  updatePandingReducer,
  filterHubReducer,
  commentsReducer,
  deletePandingReducer,
  getCategoriesReducer,
  deleteMemoriesReducer,
  deleteTimelineReducer,
  updateMemoriesForSignupReducer,
  createPandingReducer,
  getParticipantDataReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
