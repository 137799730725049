import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./TextDescription.module.scss";
import { FC } from "react";

interface ITextDescription {
  value: any;
  setValue: any;
}

export const TextDescription: FC<ITextDescription> = ({ value, setValue }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: "" }, { align: "right" }],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "size",
    "align",
    "background",
    "direction",
    "code-block",
    "code",
  ];

  return (
    <ReactQuill
      className={styles.wrapper}
      theme="snow"
      modules={modules}
      formats={formats}
      value={value}
      onChange={(text, delta, source, editor) => {
        if (source == "user") {
          setValue(text);
        }
      }}
    />
  );
};
