import styles from "./Button.module.scss"
import { FC } from "react";

interface IButton {
  title: string;
  sx?: {};
  action: () => void
}

export const Button: FC<IButton> = ({ title, sx, action }) => {
  return <div style={sx} onClick={() => action()} className={styles.wrapper}>{title}</div>;
};
