import styles from "./UploadContainer.module.scss";
import photo from "../../../../assets/icons/photo-icon.png";
import { Link } from "react-router-dom";
import { MultiUpload } from "./MultiUpload";

export const UploadContainer = ({
}) => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>

        <div className={styles.line}>
          <div className={styles.element}>
            <div className={styles.block}>
              <MultiUpload />
            </div>
            <p>Device</p>
          </div>

          <div className={styles.element}>
            <div className={styles.block}>
              <Link to="/Scattered-guest">
                <img src={photo} alt="photo" />
              </Link>
            </div>
            <p>Add from scattered pictures</p>
          </div>
        </div>
       
      </div>
    </div>
  );
};
