import { FC, ReactNode, useCallback } from "react";
import styles from "./Backgrounds.module.scss";
import Moment from "react-moment";
import { TailSpin } from "react-loader-spinner";


interface IBackgrounds {
  content?: string;
  children?: ReactNode;
  firstDate?: string;
  logo?: string;
  lastDate?: string;
  isLoading?: boolean;
  background: string;
}

export const Backgrounds: FC<IBackgrounds> = ({
  content,
  children,
  firstDate,
  logo,
  lastDate,
  isLoading,
  background
}) => {

  return (
    <div className={styles.wrapper}>
      <img className={styles.background} src={content ? `https://storage.googleapis.com/bucket_for_h4m/${content}` : background} alt="Backgrounds" />
      <div className={styles.container}>
        {children}
        {isLoading ? (
          <time className={styles.dateOfCreate}>
            <TailSpin
              height="40"
              width="40"
              color="#fff"
              ariaLabel="tail-spin-loading"
              radius="1"
              visible={true}
            />
          </time>
        ) : (
          <time className={styles.dateOfCreate}>
            {firstDate && <Moment format="DD.MM.YYYY">{firstDate}</Moment>}
          </time>
        )}
        {isLoading ? (
          <time className={styles.dateOfCreate}>
            <TailSpin
              height="40"
              width="40"
              color="#fff"
              ariaLabel="tail-spin-loading"
              radius="1"
              visible={true}
            />
          </time>
        ) : (
          <time className={styles.dateOfDeath}>
            {lastDate && <Moment format="DD.MM.YYYY">{lastDate}</Moment>}
          </time>
        )}
        {logo && (
          <div className={styles.logo}>
            <p>Hunt4Memories</p>
            <img src={logo} alt="logo" />
          </div>
        )}
      </div>
    </div>
  );
};
