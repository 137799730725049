import { Backgrounds, NavigateBar, PopupPicture } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { useState } from "react";
import { EditContainer } from "./ui/EditContainer";

export const EditPanding = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});
  
  return (
    <Layout>
      <NavigateBar />
      <Backgrounds background={Background} logo={Download}>
        <EditContainer setOpenPopup={setOpenPopup} setGetPhoto={setGetPhoto} />
        {openPopup && <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} />}
      </Backgrounds>
    </Layout>
  );
};