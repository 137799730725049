import { Dispatch, FC, SetStateAction, useLayoutEffect, useRef } from "react";
import styles from "./SmallRadioParticipants.module.scss";

interface ISmallRadioParticipants {
  setValue: Dispatch<SetStateAction<string>>;
  value?: string;
  sx?: {};
}

export const SmallRadioParticipants: FC<ISmallRadioParticipants> = ({
  setValue,
  value,
  sx,
}) => {
  const selectedValueRefs = useRef<string>("family");

  const handleRadioChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    selectedValueRefs.current = selectedValue;
    if (setValue) {
      setValue(selectedValue);
    }
  };

  useLayoutEffect(() => {
    if (setValue) {
      setValue(selectedValueRefs.current);
    }
  }, [setValue]);

  const currentPrivacys = value || selectedValueRefs.current;

  return (
    <div style={sx} className={styles.container}>
      <div className={styles.item}>
        <input
          type="radio"
          id="radio9"
          name="radioGroupss"
          value="family"
          checked={currentPrivacys === "family"}
          onChange={handleRadioChanges}
        />
        <label htmlFor="radio9">
          <div className={styles.column}>
            <h4>Family</h4>
          </div>
        </label>
      </div>
      <div className={styles.item}>
        <input
          type="radio"
          id="radio8"
          name="radioGroupss"
          value="friend"
          checked={currentPrivacys === "friend"}
          onChange={handleRadioChanges}
        />
        <label htmlFor="radio8">
          <div className={styles.column}>
            <h4>Friends</h4>
          </div>
        </label>
      </div>
    </div>
  );
};
