import styles from "./FieldDescription.module.scss";
import { Dispatch, FC, SetStateAction } from "react";
import ReactQuill from "react-quill";

interface IFieldDescription {
  label: string;
  sx?: {};
  value: string | any;
  setValue: Dispatch<SetStateAction<any>>;
  useMask?: boolean;
}

const modules = {
  toolbar: false,
  clipboard: {
    matchVisual: false,
  },
};

export const FieldDescription: FC<IFieldDescription> = ({
  label,
  sx,
  value,
  setValue,
  useMask,
}) => {
  return (
    <div className={styles.wrapper}>
      <label htmlFor={label}>{label}</label>
      <ReactQuill
        theme="snow"
        onChange={(newValue: string) => setValue(newValue)}
        value={value}
        modules={modules}
        className={styles.textarea}
        style={sx}
      />
    </div>
  );
};
