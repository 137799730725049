import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IBody } from "./types";

export const fetchGetParticipantData = createAsyncThunk(
  "getParticipantData/fetchGetParticipantData",
  async (body: IBody, thunkAPI) => {
    try {
      const response = await axiosConfig.get<string>(`/timeline/${body.id}/getParticipantData/${body.author}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "get participants failed"
      );
    }
  }
);
