import { FC, useRef, useState } from "react";
import styles from "./MultiUpload.module.scss";
import plus from "../../../../assets/menu/create.png";

interface IMultiUpload {
  setImage?: any;
}

export const MultiUpload: FC<IMultiUpload> = ({ setImage }) => {
  const [imageUrls, setImageUrls] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileList = Array.from(files);
      setImage(fileList);
      const urls = fileList.map((file) => URL.createObjectURL(file));
      setImageUrls(urls);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.block}>
          <input
            type="file"
            name=""
            id="upload"
            onChange={handleImageChange}
            ref={fileInputRef}
            multiple
          />
        </div>
      </div>
    </div>
  );
};
