import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDeletePanding } from "../../../api/fetchDeletePanding";

interface UserState {
  deleteAction: string;
  Loading: boolean;
  errors: null;
}

const initialState: UserState = {
  deleteAction: "",
  Loading: false,
  errors: null,
};

export const deletePandingReducer = createSlice({
  name: "deletePanding",
  initialState,
  reducers: {
    resetDeletePanding: (state) => {
        state.deleteAction = initialState.deleteAction;
        state.errors = initialState.errors;
    },
  },
  extraReducers: {
    [fetchDeletePanding.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.Loading = false;
      state.errors = null;
      state.deleteAction = action.payload;
    },
    [fetchDeletePanding.pending.type]: (state) => {
      state.Loading = true;
    },
    [fetchDeletePanding.rejected.type]: (state, action: PayloadAction<any>) => {
      state.Loading = false;
      state.errors = action.payload;
    },
  },
});

export const { resetDeletePanding } = deletePandingReducer.actions;

export default deletePandingReducer.reducer;
