import { Backgrounds, NavigateBar } from "../../components";
import { Layout } from "../../utils/Layout";
import Download from "../../assets/icons/download.png";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchSingleTimeline } from "../../store/api/fetchSingleTimeline";
import { useEffect, useState } from "react";
import { getTimelineIdToken, setTimelineNameToken } from "../../hooks/localstorage";
import Background from "../../assets/background/basic.png";

export const Home = () => {
  const dispatch = useAppDispatch();
  const { dataSingle, isLoad } = useAppSelector((state) => state.singleTimelineReducer);
  const id = getTimelineIdToken()

  const [firstDate, setFirstDate] = useState<string>("");
  const [lastDate, setLastDate] = useState<string>("");
  const [mainPhoto, setMainPhoto] = useState<string>("")

  useEffect(() => {
    dispatch(fetchSingleTimeline(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (dataSingle) {
      const timelineData = dataSingle.timeline;
      setFirstDate(timelineData?.dateOfBirth);
      setLastDate(timelineData?.dateOfDeath)
      setTimelineNameToken(timelineData?.name);
      setMainPhoto(timelineData?.mainPhoto)
    }
  }, [dataSingle]);

  return (
    <Layout>
      <NavigateBar />
      <Backgrounds content={mainPhoto} firstDate={firstDate} lastDate={lastDate} isLoading={isLoad} logo={Download} background={Background} />
    </Layout>
  );
};
