import styles from "./PopupUpdate.module.scss";
import { useState, useEffect, SetStateAction, FC, Dispatch } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getStepPicture,
  getTimelineIdToken,
  setMemoryIdToken,
  setNameToken,
  setTimelineIdToken,
} from "../../../../hooks/localstorage";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { fetchSingleTimeNonAuth } from "../../../../store/api/fetchSingleTimeNonAuth";
import {
  Button,
  DateField,
  MultiTextSelect,
  TextDescription,
  TextField,
} from "../../../../components";
import { Carousel } from "../Carousel";
import { fetchFilesNonAuth } from "../../../../store/api/fetchFilesNonAuth";
import { ParticipantChanges } from "../../../CreateMemory/ui/ParticipantChanges";
import plus from "../../../../assets/menu/create.png";
import minus from "../../../../assets/icons/minus.png";
import { fetchUpdateMemoGuest } from "../../../../store/api/fetchUpdateMemoGuest";
import { fetchSingleMemory } from "../../../../store/api/fetchSingleMemory";
import moment from "moment";
import { UploadContainer } from "../UploadImages";
import { fetchUpdateFilesMemoriess } from "../../../../store/api/fetchUpdateFilesMemories";
import { resetUpdateFilesNonAuth } from "../../../../store/feature/nonAuth/updateFilesNonAuth";
import { resetUpdateMemoryData } from "../../../../store/feature/memory/updateMemoGuest";

interface IPopupUpdate {
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
  setViewBlock: (value: boolean) => void;
}

export const PopupUpdate: FC<IPopupUpdate> = ({
  setOpenPopup,
  setGetPhoto,
  setViewBlock,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dataSingle } = useAppSelector((state) => state.singleTimeNonAuthReducer);
  const { files } = useAppSelector((state) => state.filesNonAuthReducer);
  const { update, result, errors } = useAppSelector((state) => state.updateMemoGuestReducer);
  const { data } = useAppSelector((state) => state.singleMemoryReducer);
  const { responseData, err } = useAppSelector((state) => state.updateFilesNonAuthReducer);

  const { id, type, ...guest } = useParams();
  const timelineSingle = getTimelineIdToken();
  const picture = getStepPicture();

  const [changeParticipant, setChangeParticipant] = useState<boolean>(false);
  const [timeline] = useState<any>(type);
  const [memory] = useState<any>(id);
  const [names] = useState<any>(guest["*"]);
  const [visualCarousel, setVisualCarousel] = useState<any>([]);
  const [additionalPicture, setAdditionalPicture] = useState<any>([]);
  const [step, setStep] = useState(1);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const [name, setName] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [participants, setParticipants] = useState<string[]>([]);
  const [cv, setCv] = useState<string>("");
  const [privacy, setPrivacy] = useState<string>("");
  const [bulkPageFiles, setBulkPageFiles] = useState<any>([]);
  const [memoryFiles, setMemoryFiles] = useState<any>([]);

  useEffect(() => {
    setTimelineIdToken(timeline);
    setMemoryIdToken(memory);
    setNameToken(names);
  }, [id, type, guest]);

  useEffect(() => {
    setVisualCarousel([
      ...memoryFiles,
      ...bulkPageFiles,
      ...update?.bulkPageFiles,
    ]);
  }, [memoryFiles, bulkPageFiles, update?.bulkPageFiles]);

  useEffect(() => {
    dispatch(fetchSingleTimeNonAuth(timelineSingle));
    dispatch(fetchFilesNonAuth({ timelineSingle, names }));
  }, [memoryFiles, bulkPageFiles, update?.bulkPageFiles]);

  useEffect(() => {
    if (picture === "picture") {
      setStep(2);
    }
  }, []);

  useEffect(() => {
    if (result.id !== null && update.files.length <= 0) {
      dispatch(resetUpdateMemoryData());
    } else if (
      result.id !== null &&
      update.files.length > 0 &&
      !isRequestSent
    ) {
      const formData: any = new FormData();
      update.files.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("timeline", timeline || "");
      dispatch(fetchUpdateFilesMemoriess({ formData, ids: result.id || "" }));
      setIsRequestSent(true);
    } else if (responseData === "OK" && isRequestSent) {
      dispatch(resetUpdateMemoryData());
      dispatch(resetUpdateFilesNonAuth());
    }
  }, [
    result.id,
    responseData,
    files,
    isRequestSent,
    navigate,
    dispatch,
    timeline,
  ]);

  const updateDesc = `${`<p><b>${names}: </b>${description}</p> `} `

  const hanlerNext = () => {
    dispatch(
      fetchUpdateMemoGuest({
        name,
        location,
        id: memory,
        cv,
        date,
        participants,
        description: description.length > 0 ? updateDesc : description,
        privacy,
        bulkPageFiles: visualCarousel,
        guestName: names,
      })
    );
    setStep((prev: number) => prev + 1);
  };

  useEffect(() => {
    dispatch(fetchSingleMemory(memory));
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      const {
        name,
        date,
        location,
        participants,
        cv,
        privacy,
        bulkPageFiles,
        memoryFiles,
      } = data;
      setName(name);
      setLocation(location);
      setParticipants(participants);
      setCv(cv);
      setPrivacy(privacy);
      setBulkPageFiles(bulkPageFiles);
      setMemoryFiles(memoryFiles);

      const formattedDate = moment.utc(date).format("YYYY-MM-DD");
      setDate(formattedDate);
    }
  }, [data]);

  return (
    <div className={styles.wrapper} onClick={() => setViewBlock(false)}>
      {step === 1 && (
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <h1>
            {dataSingle?.author} invited you to assist completing the memory
          </h1>
          <Carousel
            hiddenDelete
            setOpenPopup={setOpenPopup}
            setGetPhoto={setGetPhoto}
            picture={visualCarousel}
            setPicture={setVisualCarousel}
            setAdditionalPicture={setAdditionalPicture}
          />
          <p>{dataSingle?.name} Timeline</p>
          <Button
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "40px",
            }}
            title="Join Timeline"
            action={() => {
              setStep((prev: number) => prev + 1);
            }}
          />
        </div>
      )}
      {step === 2 && (
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <span>Update a memory</span>
          <h3>Upload Media</h3>
          <UploadContainer />
          <Button
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "40px",
              textAlign: "right",
            }}
            title="Next"
            action={() => {
              setStep((prev: number) => prev + 1);
            }}
          />
        </div>
      )}
      {step === 3 && (
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <span>When did this memory occur? </span>
          <h3>Date</h3>
          <div style={{ padding: "0 0 30px 0" }}>
            <DateField
              label=""
              width="300px"
              value={date}
              setValue={setDate}
              centerMode={true}
            />
          </div>
          <Carousel
            setOpenPopup={setOpenPopup}
            setGetPhoto={setGetPhoto}
            picture={visualCarousel}
            setPicture={setVisualCarousel}
            setAdditionalPicture={setAdditionalPicture}
          />
          <Button
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "40px",
              textAlign: "right",
            }}
            title="Next"
            action={() => {
              setStep((prev: number) => prev + 1);
            }}
          />
        </div>
      )}
      {step === 4 && (
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <span>Where did this memory take place?</span>
          <h3>Location</h3>
          <TextField
            label={""}
            type="text"
            value={location}
            setValue={setLocation}
            sx={{ margin: "0 auto", padding: "0 0 30px 0", width: "300px" }}
          />
          <Carousel
            setOpenPopup={setOpenPopup}
            setGetPhoto={setGetPhoto}
            picture={visualCarousel}
            setPicture={setVisualCarousel}
            setAdditionalPicture={setAdditionalPicture}
          />
          <Button
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "40px",
              textAlign: "right",
            }}
            title="Next"
            action={() => {
              setStep((prev: number) => prev + 1);
            }}
          />
        </div>
      )}
      {step === 5 && (
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <span>Can you describe what happened in the memory?</span>
          <h3>Description</h3>
          <div style={{ padding: "0 0 60px 0" }}>
            <TextDescription value={description} setValue={setDescription} />
          </div>
          <Carousel
            setOpenPopup={setOpenPopup}
            setGetPhoto={setGetPhoto}
            picture={visualCarousel}
            setPicture={setVisualCarousel}
            setAdditionalPicture={setAdditionalPicture}
          />
          <Button
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "40px",
              textAlign: "right",
            }}
            title="Next"
            action={() => {
              setStep((prev: number) => prev + 1);
            }}
          />
        </div>
      )}
      {step === 6 && (
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <span
            className={styles.changerIcon}
            onClick={() => setChangeParticipant(!changeParticipant)}
          >
            {changeParticipant ? (
              <img src={minus} alt="minus" />
            ) : (
              <img src={plus} alt="plus" />
            )}
          </span>
          {changeParticipant ? (
            <div className={styles.blocks}>
              <ParticipantChanges />
            </div>
          ) : (
            <>
              <span>Who participated in this memory?</span>
              <h3>Participants</h3>
              <div style={{ padding: "0 0 30px 0" }}>
                <MultiTextSelect
                  value={participants}
                  setValue={setParticipants}
                  centerMode
                  sx={{ margin: "0 auto", width: "300px" }}
                />
              </div>
              <Carousel
                setOpenPopup={setOpenPopup}
                setGetPhoto={setGetPhoto}
                picture={visualCarousel}
                setPicture={setVisualCarousel}
                setAdditionalPicture={setAdditionalPicture}
              />
              <Button
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "40px",
                  textAlign: "right",
                }}
                title="Update"
                action={hanlerNext}
              />
            </>
          )}
        </div>
      )}
      {step === 7 && (
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          {/* <img className={styles.close} onClick={() => setViewBlock(false)} src={minus} alt="minus" /> */}
          <div className={styles.errorContainer}>
            <h3>
              {errors || err ? (
                "Update not successful"
              ) : (
                <p>
                  Thank you for helping to Fill the missing pieces in{" "}
                  <span>{dataSingle?.author}</span> Memory
                </p>
              )}
            </h3>
            <p className={styles.error}>{errors || err}</p>
            {
              errors || err ?
              <Button
              sx={{ marginTop: "20px" }}
              title="Update memory again"
              action={() => window.location.reload()}
            /> : null
            }
          </div>
        </div>
      )}
    </div>
  );
};
