import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import styles from "./Books.module.scss";
import { fetchGetMemories } from "../../../../store/api/fetchGetMemories";
import {
  getTimelineIdToken,
  setMemoryIdToken,
} from "../../../../hooks/localstorage";
import { TailSpin } from "react-loader-spinner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow from "../../../../assets/icons/arrow-down.svg";
import Moment from "react-moment";
import Masonry from "react-masonry-css";
import Share from "../../../../assets/icons/share-icon.png";
import Edit from "../../../../assets/icons/edit-icon.png";
import { Link } from "react-router-dom";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

interface IBooks {
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
  setOpenShare: Dispatch<SetStateAction<boolean>>;
  setGetMemoryID: (value: {}) => void;
}

export const Books: FC<IBooks> = ({
  setOpenPopup,
  setGetPhoto,
  setOpenShare,
  setGetMemoryID,
}) => {
  const dispatch = useAppDispatch();
  const id = getTimelineIdToken();
  const [selectedButton, setSelectedButton] = useState("");

  const { data, isLoading } = useAppSelector(
    (state) => state.getMemoriesReducer
  );

  const [memories, setMemories] = useState<any>([]);
  useEffect(() => {
    setMemories(data);
  }, [data]);

  const { categoryData, participantData, locationData, dateData } =
    useAppSelector((state) => state.filterHubReducer);

  useEffect(() => {
    let filteredData = data;

    if (categoryData) {
      filteredData = filteredData.filter((el) => el.cv === categoryData);
    }

    if (participantData) {
      const participantName = participantData.includes("@")
        ? participantData.split("@")[0]
        : participantData;
      filteredData = filteredData.filter((el) =>
        el.participants.includes(participantName)
      );
    }

    if (locationData) {
      filteredData = filteredData.filter((el) => el.location === locationData);
    }

    if (dateData) {
      const [day, month, year] = dateData.split("-");
      const searchDate = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
      filteredData = filteredData.filter(
        (el) =>
          new Date(el.date).toLocaleDateString() ===
          searchDate.toLocaleDateString()
      );
    }

    setMemories(filteredData);
  }, [categoryData, data, dateData, locationData, participantData]);

  useEffect(() => {
    dispatch(fetchGetMemories(id));
  }, [dispatch, id]);

  const openPhoto = (id: any) => {
    setOpenPopup(true);
    setGetPhoto(id);
  };

  const [allPages, setAllPages] = useState<Record<string, any[]>>({});
  const [pages, setPages] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [imagesToShow, setImagesToShow] = useState<any[]>([]);

  useEffect(() => {
    if (pages.length > 0) {
      setImagesToShow([
        ...pages[page]?.bulkPageFiles,
        ...pages[page]?.memoryFiles,
      ]);
    }
  }, [page, pages]);

  const [dateButtons, setDateButtons] = useState<string[]>([]);
  useEffect(() => {
    if (dateButtons.length > 0) {
      setPages(allPages[dateButtons[0]]);
    }
  }, [allPages, dateButtons, dateButtons.length]);

  useEffect(() => {
    const years: string[] = Array.from(
      new Set(
        memories?.map((el: { date: string | number | Date }) =>
          new Date(el.date).getFullYear().toString()
        ) as string
      )
    ).sort();

    const formattedPages: Record<string, any[]> = {};

    memories?.forEach((el: { date: string | number | Date }) => {
      const key = new Date(el.date).getFullYear().toString();
      if (!formattedPages[key]) {
        formattedPages[key] = [];
      }
      formattedPages[key].push(el);
    });

    setAllPages(formattedPages);
    setDateButtons(years);
  }, [memories]);

  const handleDateButtonClick = (button: string) => {
    setPages(allPages[button]);
    setPage(0);
    setSelectedButton(button);
  };

  const handleChangePage = (direction: string) => {
    if (
      (direction === "prev" && page === 0) ||
      (direction === "next" && page + 1 > pages.length - 1)
    ) {
      return;
    }
    setPage((prev) => (direction === "next" ? (prev += 1) : (prev -= 1)));
  };

  const hebrewRegex = /[\u0590-\u05FF]/;
  const hasHebrewText =
    hebrewRegex.test(pages[page]?.name) ||
    hebrewRegex.test(pages[page]?.location) ||
    pages[page]?.participants?.some((participant: any) =>
      hebrewRegex.test(participant)
    ) ||
    hebrewRegex.test(pages[page]?.description);

  const setMemoryId = (id: string) => {
    setMemoryIdToken(id);
  };

  const handlerShare = (id: string) => {
    setOpenShare(true);
    setGetMemoryID(id);
  };

  return (
    <>
      {pages?.length > 0 && (
        <>
          <div className={styles.buttonPanel}>
            <img
              onClick={() => handlerShare(pages[page]?._id)}
              className={styles.edit}
              src={Share}
              alt="Edit"
            />
            <Link
              onClick={() => setMemoryId(pages[page]?._id)}
              to="/Edit-memory"
            >
              <img className={styles.edit} src={Edit} alt="Edit" />
            </Link>
          </div>
          {memories.length > 0 && (
            <div className={styles.wrapper}>
              <div
                className={`${styles.texts} ${
                  hasHebrewText ? styles.textsHebrew : ""
                }`}
              >
                <h1>{pages[page]?.name}</h1>
                <div className={styles.head}>
                  <Moment format="DD.MM.YYYY">{pages[page]?.date}</Moment> \\{" "}
                  {pages[page]?.location}
                </div>
                <div className={styles.subHead}>
                  {pages[page]?.participants?.join(", ")}
                </div>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: pages[page]?.description }}
                />
              </div>
              <div className={styles.picture}>
                {isLoading ? (
                  <div className={styles.loading}>
                    <TailSpin
                      height="50"
                      width="50"
                      color="#4fa94d"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      visible={true}
                    />
                  </div>
                ) : (
                  <Masonry
                    breakpointCols={{
                      default: 2,
                      700: 1,
                    }}
                    className={styles.myMasonryGrid}
                    columnClassName={styles.myMasonryGridColumn}
                  >
                    {imagesToShow?.map((path, index) => (
                      <div
                        key={index}
                        className={
                          path?.metadata
                            ? styles.myMasonryGridColumnRed
                            : styles.myMasonryGridColumn
                        }
                      >
                        {path?.path?.includes("mp4") ||
                        path?.path?.includes("MP4") ||
                        path?.path?.includes("mov") ||
                        path?.path?.includes("MOV") ||
                        path?.path?.includes("mkv") ||
                        path?.path?.includes("MKV") ? (
                          <>
                            <video
                              onClick={() => openPhoto(path)}
                              src={`https://storage.googleapis.com/bucket_for_h4m/${path.path}`}
                              controls={false}
                            />
                            <button
                              onClick={() => openPhoto(path)}
                              className={styles.playButton}
                            ></button>
                          </>
                        ) : (
                          <img
                            className={styles.images}
                            src={`https://storage.googleapis.com/bucket_for_h4m/${path.path}`}
                            alt=""
                            onClick={() => openPhoto(path)}
                          />
                        )}
                      </div>
                    ))}
                  </Masonry>
                )}
              </div>
            </div>
          )}
        </>
      )}

      <div className={styles.arrowContainer}>
        {pages?.length > 1 && (
          <>
            <button
              className={styles.button}
              onClick={() => handleChangePage("prev")}
            >
              <img className={styles.arrowLeft} src={arrow} alt="arrow" />
            </button>
            <button
              className={styles.button}
              onClick={() => handleChangePage("next")}
            >
              <img className={styles.arrowRight} src={arrow} alt="arrow" />
            </button>
          </>
        )}
      </div>

      <div className={styles.buttonContainer}>
        {dateButtons
          .sort((a: string, b: string) => parseInt(a) - parseInt(b))
          .map((button: string, idx: number) => (
            <button
              className={`${styles.button} ${
                !selectedButton && idx === 0
                  ? styles.selected
                  : "" || selectedButton === button
                  ? styles.selected
                  : ""
              }`}
              key={idx}
              onClick={() => handleDateButtonClick(button)}
            >
              {button}
            </button>
          ))}
      </div>
    </>
  );
};
