import { FC, useState, useEffect } from "react";
import styles from "./ParticipantChanges.module.scss";
import { Button, TextFieldLine } from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { fetchAddParticipant } from "../../../../store/api/fetchAddParticipant";
import { getTimelineIdToken } from "../../../../hooks/localstorage";
import { SmallRadioParticipants } from "./SmallRadioParticipants";
import { resetAddParticipant } from "../../../../store/feature/participants/addParticipant";

export const ParticipantChanges = () => {
  const dispatch = useAppDispatch();
  const { data, error } = useAppSelector((state) => state.addParticipantReducer);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const id = getTimelineIdToken();

  const sendParticipant = () => {
    dispatch(fetchAddParticipant({ name, email, phone, role, id }));
  };

  useEffect(() => {
    dispatch(resetAddParticipant());
  }, [dispatch]);

  return (
    <>
      <p style={{ textAlign: "center" }}>Who did you share this memory with?</p>
      <h1>Participants</h1>
      <TextFieldLine
        label="Add name :"
        type="text"
        value={name}
        setValue={setName}
        sx={{ width: "300px" }}
      />
      <TextFieldLine
        label="Email :"
        type="text"
        value={email}
        setValue={setEmail}
        sx={{ width: "300px" }}
      />
      <TextFieldLine
        label="Phone number :"
        type="text"
        value={phone}
        setValue={setPhone}
        sx={{ width: "300px" }}
        useMask={true}
      />

      <div className={styles.messages}>
        {error ? (
          <p className={styles.error}>{error}</p>
        ) : data === "OK" ? (
          <p className={styles.done}>
            Participants has been added to the timeline
          </p>
        ) : null}
      </div>

      <SmallRadioParticipants setValue={setRole} sx={{ marginTop: "40px" }} />
      <Button
        sx={{ position: "absolute", bottom: "30px", right: "40px" }}
        title="Send"
        action={sendParticipant}
      />
    </>
  );
};
