import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDeleteMemories } from "../../../api/fetchDeleteMemories";

interface UserState {
  result: string;
  isLoad: boolean;
  er: null | any;
}

const initialState: UserState = {
  result: "",
  isLoad: false,
  er: null,
};

export const deleteMemoriesReducer = createSlice({
  name: "deleteMemories",
  initialState,
  reducers: {
    resetDeleteMemoryData: (state) => {
      state.result = initialState.result;
      state.er = initialState.er;
    },
  },
  extraReducers: {
    [fetchDeleteMemories.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoad = false;
      state.er = null;
      state.result = action.payload;
    },
    [fetchDeleteMemories.pending.type]: (state) => {
      state.isLoad = true;
    },
    [fetchDeleteMemories.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoad = false;
      state.er = action.payload;
    },
  },
});

export const { resetDeleteMemoryData } = deleteMemoriesReducer.actions;

export default deleteMemoriesReducer.reducer;
