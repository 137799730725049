import { createAsyncThunk } from "@reduxjs/toolkit";
import { ITimeline } from "./types";
import { axiosConfig } from "../axios.config";

export const fetchCreateTimeline = createAsyncThunk(
  "timeline/fetchCreateTimeline",
  async (body: ITimeline, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(body).forEach(([key, value]) => {
        if (body.file) {
          const file = new File([value], value.name, {
            type: value.type,
          });
          formData.append(key, value);
        } else {
          formData.append(key, value);
        }
      });
      const response = await axiosConfig.post<ITimeline>(
        `https://back.hunt4memories.com/api/timeline/create`,
        formData
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Timeline creation failed"
      );
    }
  }
);
