import { Layout } from "../../utils/Layout";
import { Backgrounds, NavigateBar, PopupPicture } from "../../components";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { DocumentContainer } from "./ui/DocumentContainer";
import { useState } from "react";

export const CreateDocument = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});
  return (
    <Layout>
      <NavigateBar />
      <Backgrounds logo={Download} background={Background}>
        <DocumentContainer
          setOpenPopup={setOpenPopup}
          setGetPhoto={setGetPhoto}
        />
        {openPopup && (
          <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} />
        )}
      </Backgrounds>
    </Layout>
  );
};
