import styles from "./TimeLineContainer.module.scss";
import { Button, DateField, LargeRadio, SmallRadio, TextFieldLine } from "../../../../components";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { fetchCreateTimeline } from "../../../../store/api/fetchCreateTimeline";
import { TailSpin } from "react-loader-spinner";
import { Upload, resetCreateTimelineData } from "../../../../store/feature/timeline/createTimeline";
import { UploadImages } from "../UploadImages";
import { SpecialInput } from "../SpecialInput/SpecialInput";

export const TimeLineContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, isLoading, error } = useAppSelector((state) => state.createTimelineReducer);

  const [type, setType] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [dateOfDeath, setDateOfDeath] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [privacy, setPrivacy] = useState<string>("");
  const [file, setFile] = useState<any>(null);

  const handleCreateTimeline = () => {
    dispatch(
      fetchCreateTimeline({
        type,
        name,
        dateOfBirth,
        country,
        privacy,
        file,
        dateOfDeath,
        city,
      })
    );
  };

  useEffect(() => {
    if (data === "OK") {
      navigate("/Scattered-upload");
      dispatch(resetCreateTimelineData());
      dispatch(Upload(true))
    }
  }, [data, dispatch, navigate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <LargeRadio setType={setType} />
      </div>
      <div className={styles.body}>
        <div className={styles.fields}>
          {type === "living" ? (
            <SpecialInput
              label="Name of Person :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
          ) : type === "group" ? (
            <SpecialInput
              label="Name of Group :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
          ) : type === "memorial" ? (
            <SpecialInput
              label="Name of Person :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
          ) : null}
          {type === "living" ? (
            <DateField
              label="Date of Birth :"
              width="300px"
              setValue={setDateOfBirth}
            />
          ) : type === "group" ? (
            <DateField label="Date :" width="300px" setValue={setDateOfBirth} />
          ) : type === "memorial" ? (
            <DateField
              label="Date of Birth :"
              width="300px"
              setValue={setDateOfBirth}
            />
          ) : null}
          {type === "memorial" && (
            <DateField
              label="Date of Death :"
              width="300px"
              setValue={setDateOfDeath}
            />
          )}
          {type === "memorial" && (
            <TextFieldLine
              label="City :"
              type="text"
              value={city}
              setValue={setCity}
              sx={{ width: "300px" }}
            />
          )}
          <TextFieldLine
            label="Country :"
            type="text"
            value={country}
            setValue={setCountry}
            sx={{ width: "300px" }}
          />
          <div className={styles.error}>{error}</div>
        </div>
        <UploadImages setImage={setFile} image={file} />
      </div>
      <SmallRadio setPrivacy={setPrivacy} />

      <div className={styles.buttons}>
        {isLoading ? (
          <TailSpin
            height="30"
            width="30"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
            wrapperStyle={{ display: "flex", justifyContent: "right" }}
          />
        ) : (
          <Button
            title="Create"
            action={handleCreateTimeline}
            sx={{ display: "flex", justifyContent: "right" }}
          />
        )}
      </div>
    </div>
  );
};
