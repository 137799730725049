import { Select, SelectProps } from "antd";
import styles from "./SelectText.module.scss";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getTimelineIdToken } from "../../hooks/localstorage";
import { fetchGetParticipants } from "../../store/api/fetchGetParticipants";
import { fetchSingleTimeline } from "../../store/api/fetchSingleTimeline";

interface ISelectText {
  label?: string;
  value: any;
  setValue: Dispatch<SetStateAction<any>>;
  sx: {};
  centerMode?: any;
}

export const SelectText: React.FC<ISelectText> = ({
  label,
  value,
  setValue,
  sx,
  centerMode,
}) => {
  const dispatch = useAppDispatch();
  const id = getTimelineIdToken();
  const options: SelectProps["options"] = [];
  const gridTemplateColumns: any = centerMode ? null : "180px auto";

  const { dataSingle } = useAppSelector((state) => state.singleTimelineReducer);

  useEffect(() => {
    dispatch(fetchSingleTimeline(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchGetParticipants(id));
  }, [dispatch]);

  if (dataSingle && dataSingle.timeline && dataSingle.timeline.categories) {
    for (let i = 0; i < dataSingle.timeline.categories.length; i++) {
      options.push({
        value: dataSingle.timeline.categories[i],
        label: dataSingle.timeline.categories[i],
      });
    }
  }

  const handleChange = (value: string[]) => {
    setValue(value);
  };

  return (
    <div
      className={styles.wrapper}
      style={{
        display: centerMode ? "block" : "grid",
        gridTemplateColumns,
      }}
    >
      <label htmlFor={label}>{label}</label>
      <Select
        onChange={handleChange}
        tokenSeparators={[","]}
        options={options}
        value={value}
        className="my-select"
        style={sx}
      />
    </div>
  );
};