import styles from "./TimeLineContainers.module.scss";
import {
  Button,
  DateField,
  LargeRadio,
  SmallRadio,
  TextFieldLine,
} from "../../../components";
import { FC, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { fetchSingleTimeline } from "../../../store/api/fetchSingleTimeline";
import { getTimelineIdToken } from "../../../hooks/localstorage";
import { UploadImages } from "../ui/UploadImages";
import moment from "moment";
import { fetchUpdateTimeline } from "../../../store/api/fetchUpdateTimeline";
import { useNavigate } from "react-router-dom";
import { fetchUpdatePhotoTimeline } from "../../../store/api/fetchUpdatePhotoTimeline";
import { resetUpdateTimelineData } from "../../../store/feature/timeline/updateTimeline";
import { resetUpdateTimelineFiles } from "../../../store/feature/timeline/updatePhotoTimeline";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { ParticipantsBar } from "../ui/ParticipantsBar";
import { RootState } from "../../../store";
import { CategoryBar } from "../ui/CategoryBar";
import { CategoryAdmin } from "../ui/CategoryAdmin";
import { fetchChangeRoleToAdmin } from "../../../store/api/fetchChangeRoleToAdmin";
import { resetAddParticipant } from "../../../store/feature/participants/addParticipant";
import { fetchDeleteTimeline } from "../../../store/api/fetchDeleteTimeline";
import { resetDeleteTimelineData } from "../../../store/feature/timeline/deleteTimeline";
import { SpecialInput } from "../../CreateTimeLine/ui/SpecialInput/SpecialInput";

interface ITimeLineContainers {
  setUsers: any;
  setOpenPopup: (value: boolean) => void;
  openPopup: boolean;
  setOpenAddition: (value: boolean) => void;
  openAddition: boolean;
}

export const TimeLineContainers: FC<ITimeLineContainers> = ({
  setUsers,
  setOpenPopup,
  openPopup,
  setOpenAddition,
  openAddition,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = getTimelineIdToken();

  const { dataSingle, isLoad } = useAppSelector(
    (state: RootState) => state.singleTimelineReducer
  );
  const { update, isLoading, error } = useAppSelector(
    (state) => state.updateTimelineReducer
  );
  const { responseData, err } = useAppSelector(
    (state) => state.updatePhotoTimelineReducer
  );

  const { result, loading, er } = useAppSelector(
    (state) => state.deleteTimelineReducer
  );

  const { data } = useAppSelector((state) => state.singlePandingMemoryReducer);

  const [type, setType] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [dateOfDeath, setDateOfDeath] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [privacy, setPrivacy] = useState<string>("");
  const [participants, setParticipants] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [mainPhoto, setMainPhoto] = useState("");
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [admin, setAdmin] = useState<string>("");

  const handleCreateTimeline = () => {
    const body: any = {
      type,
      name,
      dateOfBirth,
      country,
      privacy,
      city,
      id,
      categories,
      participants,
    };

    if (
      type === "memorial" &&
      dateOfDeath &&
      dateOfDeath.toString() !== "Invalid date"
    ) {
      body.dateOfDeath = dateOfDeath;
    }
    dispatch(fetchUpdateTimeline(body));
  };

  const handlerDeleteTimeline = () => {
    dispatch(fetchDeleteTimeline(id));
    sessionStorage.removeItem("timeline_id");
    sessionStorage.removeItem("timeline_name");
  };

  useEffect(() => {
    if (result === "OK") {
      navigate("/Different-timeline");
      dispatch(resetDeleteTimelineData());
    }
  }, [result, dispatch, navigate]);

  useEffect(() => {
    if (update === "OK") {
      dispatch(fetchChangeRoleToAdmin({ id, name: admin }));
    }
  }, [update]);

  useEffect(() => {
    if (update === "OK" && mainPhoto === null) {
      dispatch(resetUpdateTimelineData());
    } else if (update === "OK" && file !== null && !isRequestSent) {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(fetchUpdatePhotoTimeline([formData, id || ""]));
      setIsRequestSent(true);
    } else if (responseData === "OK" && isRequestSent) {
      dispatch(resetUpdateTimelineData());
      dispatch(resetUpdateTimelineFiles());
    }
  }, [update, responseData, file, isRequestSent, navigate, dispatch, id]);

  useEffect(() => {
    dispatch(fetchSingleTimeline(id));
    dispatch(resetAddParticipant());
  }, [dispatch, openPopup, openAddition]);

  useEffect(() => {
    dispatch(resetUpdateTimelineData());
  }, []);

  useEffect(() => {
    if (dataSingle?.timeline) {
      const {
        categories,
        type,
        name,
        dateOfBirth,
        dateOfDeath,
        city,
        country,
        participants,
        privacy,
        mainPhoto,
      } = dataSingle.timeline;
      setName(name);
      setType(type);
      setCountry(country);
      setCity(city);
      setPrivacy(privacy);
      setMainPhoto(mainPhoto);
      setParticipants(participants);
      setCategories(categories);

      const formattedBirth = moment.utc(dateOfBirth).format("YYYY-MM-DD");
      setDateOfBirth(formattedBirth);

      const formattedDeath = moment.utc(dateOfDeath).format("YYYY-MM-DD");
      setDateOfDeath(formattedDeath);
    }
  }, [dataSingle?.timeline]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <LargeRadio setType={setType} type={type} />
      </div>
      <div className={styles.body}>
        <div className={styles.fields}>
          {type === "living" ? (
            <SpecialInput
              label="Name of Person :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
          ) : type === "group" ? (
            <SpecialInput
              label="Name of Group :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
          ) : type === "memorial" ? (
            <SpecialInput
              label="Name of Person :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
          ) : null}
          {type === "living" ? (
            <DateField
              label="Date of Birth :"
              width="300px"
              value={dateOfBirth}
              setValue={setDateOfBirth}
            />
          ) : type === "group" ? (
            <DateField
              label="Date :"
              width="300px"
              value={dateOfBirth}
              setValue={setDateOfBirth}
            />
          ) : type === "memorial" ? (
            <DateField
              label="Date of Birth :"
              width="300px"
              value={dateOfBirth}
              setValue={setDateOfBirth}
            />
          ) : null}
          {type === "memorial" && (
            <DateField
              label="Date of Death :"
              width="300px"
              value={dateOfDeath}
              setValue={setDateOfDeath}
            />
          )}
          {type === "memorial" && (
            <TextFieldLine
              label="City :"
              type="text"
              value={city}
              setValue={setCity}
              sx={{ width: "300px" }}
            />
          )}
          <TextFieldLine
            label="Country :"
            type="text"
            value={country}
            setValue={setCountry}
            sx={{ width: "300px" }}
          />
          <ParticipantsBar
            participants={participants}
            setValue={setUsers}
            setOpenPopup={setOpenPopup}
            setOpenAddition={setOpenAddition}
            role="family"
          />
          <ParticipantsBar
            participants={participants}
            setValue={setUsers}
            setOpenPopup={setOpenPopup}
            setOpenAddition={setOpenAddition}
            role="friend"
          />
          <CategoryBar categories={categories} setCategories={setCategories} />
          <CategoryAdmin
            setAdmin={setAdmin}
            admin={admin}
            participants={participants}
          />
          {error || err || er ? (
            <div className={styles.error}>{error || err}</div>
          ) : (
            <div className={styles.success}>
              {update === "OK" && "Timeline successfully changed"}
            </div>
          )}
        </div>
        <UploadImages
          setImage={setFile}
          image={file}
          preview={mainPhoto}
          loading={isLoad}
        />
      </div>
      <SmallRadio setPrivacy={setPrivacy} privacy={privacy} />

      <div className={styles.buttons}>
        {isLoading || loading ? (
          <TailSpin
            height="30"
            width="30"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
            wrapperStyle={{ display: "flex", justifyContent: "right" }}
          />
        ) : (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              title="Delete"
              sx={{ marginRight: "50px" }}
              action={handlerDeleteTimeline}
            />
            <Button title="Save" action={handleCreateTimeline} />
          </div>
        )}
      </div>
    </div>
  );
};
