import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  DateField,
  FieldDescription,
  MultiTextSelect,
  SmallRadio,
  TextFieldLine,
  UploadImages,
} from "../../../../components";
import styles from "./EditContainer.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import moment from "moment";
import { fetchSingleMemory } from "../../../../store/api/fetchSingleMemory";
import { getMemoryIdToken, getNameToken, getTimelineIdToken } from "../../../../hooks/localstorage";
import { Carousel } from "../../../../components/Carousel";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { fetchUpdateFilesMemories } from "../../../../store/api/fetchUpdateFilesMemories";
import { resetUpdateMemoryData } from "../../../../store/feature/memory/updateMemories";
import { resetUpdateMemoryFiles } from "../../../../store/feature/memory/updateFilesMemories";
import { fetchUpdateMemoGuest } from "../../../../store/api/fetchUpdateMemoGuest";
import { SelectText } from "../SelectText";

interface IEditContainer {
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
}

export const EditContainer: FC<IEditContainer> = ({
  setOpenPopup,
  setGetPhoto,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const id = getMemoryIdToken();
  const timeline = getTimelineIdToken();
  const guest = getNameToken();

  const [cv, setCv] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [date, setDate] = useState<any>("");
  const [location, setLocation] = useState<string>("");
  const [participants, setParticipants] = useState<any>([]);
  const [description, setDescription] = useState<string>("");
  const [files, setFiles] = useState<any>(null);
  const [privacy, setPrivacy] = useState<string>("");
  const [bulkPageFiles, setBulkPageFiles] = useState<any>([]);
  const [memoryFiles, setMemoryFiles] = useState<any>([]);
  const [visualCarousel, setVisualCarousel] = useState<any>([]);
  const [additionalPicture, setAdditionalPicture] = useState<any>([]);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const { data, isLoading } = useAppSelector(
    (state) => state.singleMemoryReducer
  );
  const { update, result, Loading, errors } = useAppSelector(
    (state) => state.updateMemoGuestReducer
  );
  const { responseData, Load, err } = useAppSelector(
    (state) => state.updateFilesMemoriesReducer
  );

  useEffect(() => {
    if (files != null) {
      setVisualCarousel([...memoryFiles, ...bulkPageFiles, ...files]);
    }
  }, [files]);

  useEffect(() => {
    if (result.id !== null && files === null) {
      dispatch(resetUpdateMemoryData());
      window.history.back();
    } else if (result.id !== null && files !== null && !isRequestSent) {
      const formData = new FormData();
      // formData.append("files", files);
      // formData.append("timeline", timeline || "");
      files.forEach((file: string | Blob) => {
        formData.append("files", file);
        formData.append("timeline", timeline || "");
      });
      dispatch(fetchUpdateFilesMemories([formData, result.id || ""]));
      setIsRequestSent(true);
    } else if (responseData === "OK" && isRequestSent) {
      dispatch(resetUpdateMemoryData());
      dispatch(resetUpdateMemoryFiles());
      window.history.back();
    }
  }, [result.id, responseData, files, isRequestSent, navigate, dispatch, id]);

  const updateDesc = `${`<p><b>${guest}: </b>${description}</p> `} `

  const handlerUpdate = () => {
    dispatch(
      fetchUpdateMemoGuest({
        cv,
        name,
        date,
        location,
        participants,
        description: updateDesc,
        privacy,
        bulkPageFiles: additionalPicture.length > 0 ? additionalPicture : bulkPageFiles,
        memoryFiles: additionalPicture.length > 0 ? null : memoryFiles,
        id,
        guestName: guest,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchSingleMemory(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (data) {
      const {
        cv,
        name,
        date,
        location,
        participants,
        privacy,
        bulkPageFiles,
        memoryFiles,
      } = data;
      setCv(cv);
      setName(name);
      setLocation(location);
      setParticipants(participants);
      setPrivacy(privacy);

      const formattedDate = moment.utc(date).format("YYYY-MM-DD");
      setDate(formattedDate);

      const mergedPicture = [...bulkPageFiles, ...memoryFiles];
      setVisualCarousel(mergedPicture);
      setBulkPageFiles(bulkPageFiles);
      setMemoryFiles(memoryFiles);
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        {(isLoading || Loading || Load) && (
          <TailSpin
            height="50"
            width="50"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        )}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.texts}>
          <div className={styles.column}>
            <SelectText
              label="Master headlines(CV) :"
              value={cv}
              setValue={setCv}
              sx={{ width: "300px" }}
            />
            <TextFieldLine
              label="Name :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
            <DateField
              label="Date :"
              width="300px"
              value={date}
              setValue={setDate}
            />
            <TextFieldLine
              label="Location :"
              type="text"
              value={location}
              setValue={setLocation}
              sx={{ width: "300px" }}
            />
            <MultiTextSelect
              label="Participants :"
              value={participants}
              setValue={setParticipants}
              sx={{ width: "300px" }}
            />
            <FieldDescription
              label="Description :"
              value={description}
              setValue={setDescription}
              sx={{ width: "300px" }}
            />
          </div>
          <div className={styles.radio}>
            <SmallRadio setPrivacy={setPrivacy} privacy={privacy} />
          </div>
          <div className={styles.carousel}>
            <p>Additional media :</p>
            <Carousel
              setOpenPopup={setOpenPopup}
              setGetPhoto={setGetPhoto}
              picture={visualCarousel}
              setPicture={setVisualCarousel}
              setAdditionalPicture={setAdditionalPicture}
            />
          </div>
        </div>
        <div className={styles.texts}>
          <UploadImages
            image={files}
            setImage={setFiles}
            preview={bulkPageFiles}
          />
        </div>
      </div>
      <span
        style={{
          display: "flex",
          justifyContent: "right",
          color: "red",
          fontSize: "13px",
          height: "20px",
        }}
      >
        {errors || err}
      </span>
      <Button
        sx={{ display: "flex", justifyContent: "right" }}
        title="Update"
        action={handlerUpdate}
      />
    </div>
  );
};
