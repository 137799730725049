import { createAsyncThunk } from "@reduxjs/toolkit";
import { IMemories } from "./types";
import { axiosConfig } from "../axios.config";

export const fetchCreatePanding = createAsyncThunk(
  "createPanding/fetchCreatePanding",
  async (body: IMemories, thunkAPI) => {
    try {
      const response = await axiosConfig.post<IMemories>(`memory/create`, body);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Memory create failed"
      );
    }
  }
);
