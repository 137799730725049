import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IAdmin } from "./types";

export const fetchChangeRoleToAdmin = createAsyncThunk(
  "changeRoleToAdmin/fetchChangeRoleToAdmin",
  async ({ id, ...body }: { id: string | null } & IAdmin, thunkAPI) => {
    try {
      const response = await axiosConfig.post<IAdmin>(
        `timeline/changeRoleToAdmin/${id}`,
        body
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "admin update failed"
      );
    }
  }
);
