import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  data: any | null;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: null,
  isLoading: false,
  error: "",
};

export const singlePandingMemoryReducer = createSlice({
  name: "singlePandingMemory",
  initialState,
  reducers: {
    addMemory: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
  extraReducers: {},
});

export const { addMemory } = singlePandingMemoryReducer.actions;

export default singlePandingMemoryReducer.reducer;
