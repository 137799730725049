import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdateMemories } from "../../../api/fetchUpdateMemories";

interface UserState {
  update: string;
  Loading: boolean;
  errors: null;
}

const initialState: UserState = {
  update: "",
  Loading: false,
  errors: null,
};

export const updateMemoriesReducer = createSlice({
  name: "updateMemories",
  initialState,
  reducers: {
    resetUpdateMemoryData: (state) => {
      state.update = initialState.update;
    },
  },
  extraReducers: {
    [fetchUpdateMemories.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.Loading = false;
      state.errors = null;
      state.update = action.payload;
    },
    [fetchUpdateMemories.pending.type]: (state) => {
      state.Loading = true;
    },
    [fetchUpdateMemories.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.Loading = false;
      state.errors = action.payload;
    },
  },
});

export const { resetUpdateMemoryData } = updateMemoriesReducer.actions;

export default updateMemoriesReducer.reducer;
