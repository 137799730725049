import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdateFilesMemories, fetchUpdateFilesMemoriess } from "../../../api/fetchUpdateFilesMemories";

interface UserState {
  responseData: string;
  Load: boolean;
  err: null;
}

const initialState: UserState = {
  responseData: "",
  Load: false,
  err: null,
};

export const updateFilesMemoriessReducer = createSlice({
  name: "updateFilesMemoriess",
  initialState,
  reducers: {
    resetUpdateMemoryFiless: (state) => {
      state.responseData = initialState.responseData;
    },
  },
  extraReducers: {
    [fetchUpdateFilesMemoriess.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.Load = false;
      state.err = null;
      state.responseData = action.payload;
    },
    [fetchUpdateFilesMemoriess.pending.type]: (state) => {
      state.Load = true;
    },
    [fetchUpdateFilesMemoriess.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.Load = false;
      state.err = action.payload;
    },
  },
});

export const { resetUpdateMemoryFiless } = updateFilesMemoriessReducer.actions;

export default updateFilesMemoriessReducer.reducer;
