import { FC, useEffect, useState } from "react";
import styles from "./NavigateBar.module.scss";
import { Link, NavLink } from "react-router-dom";
import arrow from "../../../assets/icons/arrow-down.svg";

import Profile from "../../../assets/menu/profile.png";
import Plus from "../../../assets/menu/plus.png";
import Change from "../../../assets/menu/change.png";
import Scattered from "../../../assets/menu/scattered.png";
import Settins from "../../../assets/menu/settins.png";
import Message from "../../../assets/menu/message.png";
import Create from "../../../assets/menu/create.png";
import Logout from "../../../assets/menu/log-out.png";
import {
  getNameToken,
  getSuperUserToken,
  getTimelineIdToken,
  getTimelineNameToken,
} from "../../../hooks/localstorage";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { fetchSingleTimeline } from "../../../store/api/fetchSingleTimeline";

interface INavigateBar {}

export const NavigateBar: FC<INavigateBar> = () => {
  const profile = getNameToken();
  const name = getTimelineNameToken();
  const superUser = getSuperUserToken();
  const id = getTimelineIdToken();
  const dispatch = useAppDispatch();
  const [short, setShort] = useState(false);
  console.log(superUser, "superUser");
  const { dataSingle, isLoad } = useAppSelector(
    (state) => state.singleTimelineReducer
  );

  useEffect(() => {
    dispatch(fetchSingleTimeline(id));
  }, [dispatch]);

  const activeLink = styles.activeLink;
  const normalLink = styles.normalLink;

  const removeToken = () => {
    sessionStorage.removeItem("timeline_id");
    sessionStorage.removeItem("timeline_name");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("JWT_TOKEN");
    sessionStorage.removeItem("super_TOKEN");
    sessionStorage.removeItem("memory_id");
    if (!sessionStorage.getItem("JWT_TOKEN")) {
      window.location.href = "/sign-in";
    }
  };

  return (
    <div
      style={short ? { width: "130px" } : { width: "650px" }}
      className={styles.wrapper}
    >
      <div className={styles.head}>
        <div className={styles.burgerColumn} onClick={() => setShort(!short)}>
          <div className={styles.burgerLine}></div>
          <div className={styles.burgerLine}></div>
          <div className={styles.burgerLine}></div>
        </div>
        <Link to="/" className={styles.name}>
          {!short && profile}
        </Link>
      </div>

      <div
        style={
          short
            ? { justifyContent: "center" }
            : { justifyContent: "space-between" }
        }
        className={styles.body}
      >
        <ul className={styles.menu}>
          {name && !short && (
            <p>
              <img src={arrow} alt="arrow" />
              {name}
            </p>
          )}
          {name && (
            <>
              <li>
                <NavLink
                  to="/Timeline"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <img src={Profile} alt="Profile" />
                  {!short && <span>Watch TimeLine</span>}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Create-memory"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <img src={Plus} alt="Plus" />
                  {!short && <span>Create a Memory</span>}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Document"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <img src={Change} alt="Change" />
                  {!short && <span>Important Document</span>}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Create-document"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <img src={Plus} alt="Plus" />
                  {!short && <span>Create an Important Document</span>}
                </NavLink>
              </li>
              {dataSingle?.role === "admin" && (
                <li>
                  <NavLink
                    to="/Panding"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <img src={Message} alt="Message" />
                    {!short && (
                      <span>
                        Invitations & Pending Memories(
                        {dataSingle?.timeline?.participantsActions?.length})
                      </span>
                    )}
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  to="/Scattered"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <img src={Scattered} alt="Scattered" />
                  {!short && <span>Scattered page</span>}
                </NavLink>
              </li>
              {dataSingle?.role === "admin" && (
                <li>
                  <NavLink
                    to="/Settings-timeline"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <img src={Settins} alt="Settins" />
                    {!short && <span>TimeLine Settings</span>}
                  </NavLink>
                </li>
              )}
            </>
          )}
          {/* dataSingle?.role === "admin"  */}
          {superUser === "true" ||
          (dataSingle?.role === "admin" && superUser === "false") ? (
            <li>
              <NavLink
                to="/Create-timeline"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <img src={Create} alt="Create" />
                {!short && <span>Create a new TimeLine</span>}
              </NavLink>
            </li>
          ) : null}

          <li>
            <NavLink
              to="/Different-timeline"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <img src={Change} alt="Change" />
              {!short && <span>Change to a different Timeline</span>}
            </NavLink>
          </li>
          <li>
            <NavLink to="#" onClick={removeToken}>
              <img src={Logout} alt="Logout" />
              {!short && <span>Log Out</span>}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};
