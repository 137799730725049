import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFiles } from "./types";
import { fetchFiles } from "../../api/fetchFiles";

interface UserState {
  data: IFiles[] | null;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: null,
  isLoading: false,
  error: "",
};

export const filesReducer = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFiles.fulfilled.type]: (
      state,
      action: PayloadAction<IFiles[]>
    ) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchFiles.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchFiles.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default filesReducer.reducer;
