import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { useEffect, useState } from "react";
import { Backgrounds, NavigateBar, PopupPicture } from "../../components";
import { EditContainer } from "./ui/EditContainer";
import { NonAuthBar } from "../../components/Sidebars/NonAuth-bar";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getNameToken, getTimelineIdToken } from "../../hooks/localstorage";
import { fetchGetParticipantData } from "../../store/api/fetchGetParticipantData";

export const EditNonAuth = () => {
  const dispatch = useAppDispatch();
  const author = getNameToken();
  const id = getTimelineIdToken();
  const [openPopup, setOpenPopup] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});

  const { dataParticipant } = useAppSelector((state) => state.getParticipantDataReducer);

  useEffect(() => {
    dispatch(fetchGetParticipantData({ id, author }));
  }, [author, id, dispatch]);
  
  return (
    <Layout>
      <NonAuthBar dataParticipant={dataParticipant} />
      <Backgrounds background={Background} logo={Download}>
        <EditContainer setOpenPopup={setOpenPopup} setGetPhoto={setGetPhoto} />
        {openPopup && <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} />}
      </Backgrounds>
    </Layout>
  );
};
