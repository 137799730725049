import { Dispatch, FC, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./LargeRadio.module.scss";

interface ILargeRadio {
  setType?: Dispatch<SetStateAction<string>>;
  type?: string;
}

export const LargeRadio: FC<ILargeRadio> = ({ setType, type }) => {
  const selectedValueRef = useRef<string>("living");
  const [selectedValue, setSelectedValue] = useState("living");

  useEffect(() => {
    if (type) {
      selectedValueRef.current = type;
      setSelectedValue(type);
    }
  }, [type]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    selectedValueRef.current = selectedValue;
    setSelectedValue(selectedValue);
    if (setType) {
      setType(selectedValue);
    }
  };

  useLayoutEffect(() => {
    if (setType) {
      setType(selectedValueRef.current);
    }
  }, [setType]);

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <input
          type="radio"
          id="radio1"
          name="radioGroup"
          value="living"
          checked={selectedValue === "living"}
          onChange={handleRadioChange}
        />
        <label htmlFor="radio1">
          <div className={styles.column}>
            <h4>Living</h4>
            <p>Person or pet</p>
          </div>
        </label>
      </div>
      <div className={styles.item}>
        <input
          type="radio"
          id="radio2"
          name="radioGroup"
          value="memorial"
          checked={selectedValue === "memorial"}
          onChange={handleRadioChange}
        />
        <label htmlFor="radio2">
          <div className={styles.column}>
            <h4>Memorial</h4>
            <p>Person or pet</p>
          </div>
        </label>
      </div>
      <div className={styles.item}>
        <input
          type="radio"
          id="radio3"
          name="radioGroup"
          value="group"
          checked={selectedValue === "group"}
          onChange={handleRadioChange}
        />
        <label htmlFor="radio3">
          <div className={styles.column}>
            <h4>Group</h4>
            <p>Person or pet</p>
          </div>
        </label>
      </div>
    </div>
  );
};