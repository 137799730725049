import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFileMetadata } from "../../../api/fetchFileMetadata";

interface UserState {
  data: string | null;
  Loading: boolean;
  error: string;
}

const initialState: UserState = {
  data: null,
  Loading: false,
  error: "",
};

export const fileMetadataReducer = createSlice({
  name: "fileMetadata",
  initialState,
  reducers: {
    resetMetadata: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [fetchFileMetadata.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.Loading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchFileMetadata.pending.type]: (state) => {
      state.Loading = true;
    },
    [fetchFileMetadata.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.Loading = false;
      state.error = action.payload;
    },
  },
});

export const { resetMetadata } = fileMetadataReducer.actions;

export default fileMetadataReducer.reducer;
