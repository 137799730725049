import { Dispatch, FC, SetStateAction, useState } from "react";
import {
  Button,
  DateField,
  MultiTextSelect,
  TextDescription,
  TextField,
} from "../../../../components";
import styles from "./MemoryCreatePopup.module.scss";
import { useAppDispatch } from "../../../../hooks/redux";
import {
  addDate,
  addDescription,
  addLocation,
  addName,
  addParticipants,
} from "../../../../store/feature/memory/createMemories";
import { ParticipantChanges } from "../ParticipantChanges";
import plus from "../../../../assets/menu/create.png";
import minus from "../../../../assets/icons/minus.png";

interface IMemoryCreatePopup {
  setClose: (value: boolean) => void;
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
}

export const MemoryCreatePopup: FC<IMemoryCreatePopup> = ({
  setClose,
  setStep,
  step,
}) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [participants, setParticipants] = useState<any>([]);
  const [changeParticipant, setChangeParticipant] = useState<boolean>(false);

  const hanlerNext = () => {
    setStep((prev: number) => prev + 1);
    if (step === 1) {
      dispatch(addName(name));
    }
    if (step === 2) {
      dispatch(addDate(date));
    }
    if (step === 3) {
      dispatch(addLocation(location));
    }
    if (step === 4) {
      dispatch(addParticipants(participants));
    }
    if (step === 5) {
      dispatch(addDescription(description));
      setClose(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {step === 1 && (
          <div className={styles.block}>
            <p>Create a memory</p>
            <h1>Name the memory</h1>
            <TextField
              sx={{ width: "300px", margin: "0 auto" }}
              label=""
              type="text"
              value={name}
              setValue={setName}
            />
            <Button
              sx={{ position: "absolute", bottom: "40px", right: "60px" }}
              title="Next"
              action={hanlerNext}
            />
          </div>
        )}
        {step === 2 && (
          <div className={styles.block}>
            <p>When did this memory occur?</p>
            <h1>Date</h1>
            <DateField
              setValue={setDate}
              value={date}
              width="300px"
              centerMode
            />
            <Button
              sx={{ position: "absolute", bottom: "40px", right: "60px" }}
              title="Next"
              action={hanlerNext}
            />
          </div>
        )}
        {step === 3 && (
          <div className={styles.block}>
            <p>Where did this memory take place?</p>
            <h1>Location</h1>
            <TextField
              sx={{ width: "300px", margin: "0 auto" }}
              label=""
              type="text"
              value={location}
              setValue={setLocation}
            />
            <Button
              sx={{ position: "absolute", bottom: "40px", right: "60px" }}
              title="Next"
              action={hanlerNext}
            />
          </div>
        )}
        {step === 4 && (
          <>
            <span
              className={styles.changerIcon}
              onClick={() => setChangeParticipant(!changeParticipant)}
            >
              {changeParticipant ? (
                <img src={minus} alt="minus" />
              ) : (
                <img src={plus} alt="plus" />
              )}
            </span>
            {changeParticipant ? (
              <div className={styles.blocks}>
                <ParticipantChanges />
              </div>
            ) : (
              <div className={styles.block}>
                <p>Who participated in this memory?</p>
                <h1>Participants</h1>
                <MultiTextSelect
                  value={participants}
                  setValue={setParticipants}
                  sx={{ width: "300px" }}
                  centerMode
                />
                <Button
                  sx={{ position: "absolute", bottom: "40px", right: "60px" }}
                  title="Next"
                  action={hanlerNext}
                />
              </div>
            )}
          </>
        )}
        {step === 5 && (
          <div className={styles.block}>
            <p>Can you describe what happened in the memory?</p>
            <h1>Description</h1>
            <TextDescription value={description} setValue={setDescription} />
            <Button
              sx={{ position: "absolute", bottom: "20px", right: "60px" }}
              title="Next"
              action={hanlerNext}
            />
          </div>
        )}
      </div>
    </div>
  );
};
