import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchGetDocument } from "../../../api/fetchGetDocument";
import { IDocument } from "./types";

interface UserState {
  data: IDocument[];
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: [],
  isLoading: false,
  error: "",
};

export const getDocumentReducer = createSlice({
  name: "getDocument",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGetDocument.fulfilled.type]: (state, action: PayloadAction<IDocument[]>) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchGetDocument.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchGetDocument.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default getDocumentReducer.reducer;
