import styles from "./PopupPicture.module.scss";
import { FC, useState, useEffect, SetStateAction } from "react";
import arrow from "../../assets/icons/rotate-icon.png";
import close from "../../assets/menu/plus.png";
import { TailSpin } from "react-loader-spinner";
import { TextField, TextFieldLine } from "..";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getTimelineIdToken } from "../../hooks/localstorage";
import { fetchFileMetadata } from "../../store/api/fetchFileMetadata";
import { resetMetadata } from "../../store/feature/scattered/addComment";
import Edit from "../../assets/icons/edit-icon.png";

interface IPopupPicture {
  getPhoto: any;
  setOpenPopup?: any;
  scattered?: boolean;
}

export const PopupPicture: FC<IPopupPicture> = ({
  getPhoto,
  setOpenPopup,
  scattered,
}) => {
  const dispatch = useAppDispatch();
  const [rotatePicture, setRotatePicture] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState<string>("");
  const [path] = useState<string>(getPhoto?.path);
  const [address] = useState<string>(getPhoto?.metadata?.address);
  const id = getTimelineIdToken();

  const { data, Loading, error } = useAppSelector(
    (state) => state.fileMetadataReducer
  );

  const handlerClick = async () => {
    await dispatch(fetchFileMetadata({ id, path, address, additionalText: text }));
  };

  useEffect(() => {
    dispatch(resetMetadata());
  }, []);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setIsLoading(false);
    };
    image.src = `https://storage.googleapis.com/bucket_for_h4m/${getPhoto?.path}`;
  }, [getPhoto]);

  return (
    <div className={styles.wrapper}>
      {Loading ? (
        <TailSpin
          height="50"
          width="50"
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
        />
      ) : (
        <div className={styles.content}>
          {rotatePicture ? (
            getPhoto?.path?.includes("mp4") ||
            getPhoto?.path?.includes("MP4") ||
            getPhoto?.path?.includes("mov") ||
            getPhoto?.path?.includes("MOV") ||
            getPhoto?.path?.includes("mkv") ||
            getPhoto?.path?.includes("MKV") ? (
              <video className={styles.video} controls>
                <source
                  src={`https://storage.googleapis.com/bucket_for_h4m/${getPhoto?.path}`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                className={styles.images}
                src={`https://storage.googleapis.com/bucket_for_h4m/${getPhoto?.path}`}
              />
            )
          ) : (
            <div className={styles.block}>
              {scattered && <img src={Edit} onClick={() => setEdit(!edit)} />}
              <div className={styles.blockWrapper}>
                <p>{getPhoto?.metadata?.address}</p>
                <p>{getPhoto?.metadata?.date}</p>
                <p>{getPhoto?.metadata?.additionalText}</p>
                <p className={styles.errors}>{error && error}</p>
                <p className={styles.done}>{data && "Change has been added"}</p>
                {edit && (
                  <div className={styles.comments}>
                    <button onClick={handlerClick}>Add comment</button>
                    <TextField
                      label=""
                      type="text"
                      value={text}
                      setValue={setText}
                      sx={{ width: "150px" }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <img
            onClick={() => setRotatePicture(!rotatePicture)}
            className={styles.rotate}
            src={arrow}
            alt="arrow"
          />
          <img
            src={close}
            className={styles.close}
            onClick={() => setOpenPopup(false)}
          />
        </div>
      )}
    </div>
  );
};
