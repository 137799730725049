import { Backgrounds, SignupBar } from "../../components";
import Background from "../../assets/background/basic.png";
import { Layout } from "../../utils/Layout";

export const Signup = () => {

  return (
    <Layout>
      <SignupBar />
      <Backgrounds background={Background} />
    </Layout>
  );
};
