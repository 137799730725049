import { Dispatch, FC, SetStateAction, useLayoutEffect, useRef } from "react";
import styles from "./SmallRadio.module.scss";

interface ISmallRadio {
  setPrivacy: Dispatch<SetStateAction<string>>;
  privacy?: string;
  additionalField?: boolean;
  sx?: {};
}

export const SmallRadio: FC<ISmallRadio> = ({
  setPrivacy,
  privacy,
  additionalField,
  sx,
}) => {
  const selectedValueRef = useRef<string>("public");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    selectedValueRef.current = selectedValue;
    if (setPrivacy) {
      setPrivacy(selectedValue);
    }
  };

  useLayoutEffect(() => {
    if (setPrivacy) {
      setPrivacy(selectedValueRef.current);
    }
  }, [setPrivacy]);

  const currentPrivacy = privacy || selectedValueRef.current;

  return (
    <div style={sx} className={styles.container}>
      <div className={styles.item}>
        <input
          type="radio"
          id="radio5"
          name="radioGroups"
          value="public"
          checked={currentPrivacy === "public"}
          onChange={handleRadioChange}
        />
        <label htmlFor="radio5">
          <div className={styles.column}>
            <h4>Public</h4>
          </div>
        </label>
      </div>
      <div className={styles.item}>
        <input
          type="radio"
          id="radio4"
          name="radioGroups"
          value="private"
          checked={currentPrivacy === "private"}
          onChange={handleRadioChange}
        />
        <label htmlFor="radio4">
          <div className={styles.column}>
            <h4>Private</h4>
          </div>
        </label>
      </div>
    </div>
  );
};
