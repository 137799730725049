import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import styles from "./Books.module.scss";

import { TailSpin } from "react-loader-spinner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow from "../../../assets/icons/arrow-down.svg";
import Moment from "react-moment";
import Masonry from "react-masonry-css";
import Edit from "../../../assets/icons/edit-icon.png";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
  getNameToken,
  getTimelineIdToken,
  setMemoryIdToken,
} from "../../../hooks/localstorage";
import { fetchGetDocument } from "../../../store/api/fetchGetDocument";
import { fetchSingleTimeNonAuth } from "../../../store/api/fetchSingleTimeNonAuth";

interface IBooks {
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
}

export const Books: FC<IBooks> = ({ setOpenPopup, setGetPhoto }) => {
  const dispatch = useAppDispatch();
  const id = getTimelineIdToken();
  const [selectedButton, setSelectedButton] = useState("");
  const [memories, setMemories] = useState<any>([]);
  const [allPages, setAllPages] = useState<Record<string, any[]>>({});
  const [pages, setPages] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [imagesToShow, setImagesToShow] = useState<any[]>([]);

  const { data, isLoading } = useAppSelector(
    (state) => state.getDocumentReducer
  );
  const { nameData } = useAppSelector((state) => state.filterHubReducer);
  const { dataSingle } = useAppSelector(
    (state) => state.singleTimeNonAuthReducer
  );

  useEffect(() => {
    setMemories(data);
    dispatch(fetchSingleTimeNonAuth(id));
  }, [data]);

  useEffect(() => {
    let filteredData = data;

    if (nameData) {
      filteredData = filteredData.filter((el) => el.name === nameData);
    }

    setMemories(filteredData);
  }, [nameData, data]);

  useEffect(() => {
    dispatch(fetchGetDocument(id));
  }, [dispatch, id]);

  const openPhoto = (id: any) => {
    setOpenPopup(true);
    setGetPhoto(id);
  };

  useEffect(() => {
    if (pages.length > 0) {
      setImagesToShow([...pages[page]?.documentFiles]);
    }
  }, [page, pages]);

  const [dateButtons, setDateButtons] = useState<string[]>([]);
  useEffect(() => {
    if (dateButtons.length > 0) {
      setPages(allPages[dateButtons[0]]);
    }
  }, [allPages, dateButtons, dateButtons.length]);

  useEffect(() => {
    const formattedPages: Record<string, any[]> = {};
    memories?.forEach((el: { createdAt: string | number | Date }) => {
      const key = new Date(el.createdAt).getFullYear().toString();
      if (!formattedPages[key]) {
        formattedPages[key] = [];
      }
      formattedPages[key].push(el);
    });
    setAllPages(formattedPages);
    setDateButtons(
      Object.keys(formattedPages).sort((a, b) => parseInt(a) - parseInt(b))
    );
    setPages(formattedPages[Object.keys(formattedPages)[0]] || []); // Устанавливаем первую страницу по умолчанию
    setPage(0); // Сбрасываем индекс страницы
  }, [memories]);

  const handleDateButtonClick = (button: string) => {
    setPages(allPages[button]);
    setPage(0);
    setSelectedButton(button);
  };

  const handleChangePage = (direction: string) => {
    if (
      (direction === "prev" && page === 0) ||
      (direction === "next" && page + 1 > pages.length - 1)
    ) {
      return;
    }
    setPage((prev) => (direction === "next" ? (prev += 1) : (prev -= 1)));
  };

  const hebrewRegex = /[\u0590-\u05FF]/;
  const hasHebrewText =
    hebrewRegex.test(pages[page]?.name) ||
    hebrewRegex.test(pages[page]?.location) ||
    pages[page]?.participants?.some((participant: any) =>
      hebrewRegex.test(participant)
    ) ||
    hebrewRegex.test(pages[page]?.description);

  const setMemoryId = (id: string) => {
    setMemoryIdToken(id);
  };

  return (
    <>
      {pages?.length > 0 && (
        <>
          <div className={styles.buttonPanel}>
            <p className={styles.admin}>
              Created by {dataSingle?.author} (Admin)
            </p>
            {/* <Link
              onClick={() => setMemoryId(pages[page]?._id)}
              to="/Edit-document"
            >
              <img className={styles.edit} src={Edit} alt="Edit" />
            </Link> */}
          </div>
          <div className={styles.wrapper}>
            <div
              className={`${styles.texts} ${
                hasHebrewText ? styles.textsHebrew : ""
              }`}
            >
              <h1>{pages[page]?.name}</h1>
              <div className={styles.head}>
                <Moment format="DD.MM.YYYY">{pages[page]?.createdAt}</Moment> \\{" "}
                {pages[page]?.cv}
              </div>
              <div className={styles.subHead}>
                {pages[page]?.participants?.join(", ")}
              </div>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: pages[page]?.description }}
              />
            </div>
            <div className={styles.picture}>
              {isLoading ? (
                <div className={styles.loading}>
                  <TailSpin
                    height="50"
                    width="50"
                    color="#4fa94d"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                  />
                </div>
              ) : (
                <Masonry
                  breakpointCols={{
                    default: 2,
                    700: 1,
                  }}
                  className={styles.myMasonryGrid}
                  columnClassName={styles.myMasonryGridColumn}
                >
                  {imagesToShow?.map((path, index) => (
                    <div key={index} className={styles.myMasonryGridColumn}>
                      <img
                        className={styles.images}
                        src={`https://storage.googleapis.com/bucket_for_h4m/${path.path}`}
                        alt=""
                        onClick={() => openPhoto(path)}
                      />
                    </div>
                  ))}
                </Masonry>
              )}
            </div>
          </div>
        </>
      )}

      <div className={styles.arrowContainer}>
        {pages?.length > 1 && (
          <>
            <button
              className={styles.button}
              onClick={() => handleChangePage("prev")}
            >
              <img className={styles.arrowLeft} src={arrow} alt="arrow" />
            </button>
            <button
              className={styles.button}
              onClick={() => handleChangePage("next")}
            >
              <img className={styles.arrowRight} src={arrow} alt="arrow" />
            </button>
          </>
        )}
      </div>

      <div className={styles.buttonContainer}>
        {dateButtons
          .sort((a: string, b: string) => parseInt(a) - parseInt(b))
          .map((button: string, idx: number) => (
            <button
              className={`${styles.button} ${
                !selectedButton && idx === 0
                  ? styles.selected
                  : "" || selectedButton === button
                  ? styles.selected
                  : ""
              }`}
              key={idx}
              onClick={() => handleDateButtonClick(button)}
            >
              {button}
            </button>
          ))}
      </div>
    </>
  );
};
