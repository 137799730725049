import { useEffect, useState } from "react"
import { Backgrounds, NavigateBar, PopupPicture } from "../../components"
import { Layout } from "../../utils/Layout"
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { Books } from "./ui/Books";
import { PopupUpdate } from "./ui/PopupUpdate";
import { NonAuthBar } from "../../components/Sidebars/NonAuth-bar";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchGetParticipantData } from "../../store/api/fetchGetParticipantData";
import { getNameToken, getTimelineIdToken } from "../../hooks/localstorage";

export const NonAuthTimeline = () => {
  const dispatch = useAppDispatch();
  const author = getNameToken();
  const id = getTimelineIdToken();
  const [openPopup, setOpenPopup] = useState(false);
  const [viewBlock, setViewBlock] = useState(true);
  const [getPhoto, setGetPhoto] = useState({});
  const { dataParticipant } = useAppSelector((state) => state.getParticipantDataReducer);

  useEffect(() => {
    dispatch(fetchGetParticipantData({ id, author }));
  }, [author, id, dispatch]);
  
    return (
      <Layout>
        <NonAuthBar dataParticipant={dataParticipant} />
        <Backgrounds background={Background} logo={Download}>
          <Books setOpenPopup={setOpenPopup} setGetPhoto={setGetPhoto} />
          {openPopup && <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} />}
          {viewBlock && <PopupUpdate setGetPhoto={setGetPhoto} setOpenPopup={setOpenPopup} setViewBlock={setViewBlock} />}
        </Backgrounds>
    </Layout>
    )
}