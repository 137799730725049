import { FC, useState } from "react";
import styles from "./ParticipantsBar.module.scss";

interface IParticipantsBar {
  participants: any;
  setValue: any;
  setOpenPopup: any;
  setOpenAddition: any;
  role?: string;
}

export const ParticipantsBar: FC<IParticipantsBar> = ({
  participants,
  setValue,
  setOpenPopup,
  setOpenAddition,
  role,
}) => {

  const getClick = (label: string) => {
    setValue(label);
    setOpenPopup(true);
  };

  const familyParticipants = participants.filter((participant: { role: string }) => participant.role === "family");
  const friendsParticipants = participants.filter((participant: { role: string }) => participant.role === "friend");
  return (
    <div className={styles.wrapper}>
      <label>Edit {role === "family" ? "family" : "friend"}</label>
      <div className={styles.container}>
        <div className={styles.preview}>
          {role === "family" && (
            <>
              {familyParticipants.map((item: any, index: number) => (
                <div className={styles.item} key={index}>
                  <p onClick={() => getClick(item)}>{item.name}</p>
                </div>
              ))}
            </>
          )}
          {role === "friend" && (
            <>
              {friendsParticipants.map((item: any, index: number) => (
                <div className={styles.item} key={index}>
                  <p onClick={() => getClick(item)}>{item.name}</p>
                </div>
              ))}
            </>
          )}
        </div>
        <p className={styles.plus} onClick={() => setOpenAddition(true)}>+</p>
      </div>
    </div>
  );
};
