import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFiles } from "./types";
import { fetchFilesNonAuth } from "../../../api/fetchFilesNonAuth";

interface UserState {
  files: IFiles[] | null;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  files: null,
  isLoading: false,
  error: "",
};

export const filesNonAuthReducer = createSlice({
  name: "filesNonAuth",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFilesNonAuth.fulfilled.type]: (
      state,
      action: PayloadAction<IFiles[]>
    ) => {
      state.isLoading = false;
      state.error = "";
      state.files = action.payload;
    },
    [fetchFilesNonAuth.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchFilesNonAuth.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default filesNonAuthReducer.reducer;
