import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import styles from "./Books.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Moment from "react-moment";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import Masonry from "react-masonry-css";
import { Button } from "../../../../components";
import { fetchUpdatePanding } from "../../../../store/api/fetchUpdatePanding";
import { fetchCreatePanding } from "../../../../store/api/fetchCreatePanding";
import { Link, useNavigate } from "react-router-dom";
import {
  getTimelineIdToken,
  setMemoryIdToken,
} from "../../../../hooks/localstorage";
import { fetchSingleTimeline } from "../../../../store/api/fetchSingleTimeline";
import { fetchDeletePanding } from "../../../../store/api/fetchDeletePanding";
import { resetDeletePanding } from "../../../../store/feature/panding/deletePanding";
import { resetUpdatePanding } from "../../../../store/feature/panding/updatePanding";

interface IBooks {
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
}

export const Books: FC<IBooks> = ({ setOpenPopup, setGetPhoto }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const id = getTimelineIdToken();
  const [imagesToShow, setImagesToShow] = useState<any[]>([]);

  const { data } = useAppSelector((state) => state.singlePandingMemoryReducer);
  const { dataSingle } = useAppSelector((state) => state.singleTimelineReducer);
  const { deleteAction, errors } = useAppSelector(
    (state) => state.deletePandingReducer
  );
  const { update, erro } = useAppSelector(
    (state) => state.updatePandingReducer
  );

  const { updatePanding } = useAppSelector(
    (state) => state.createPandingReducer
  );

  useEffect(() => {
    if (data?.memoryFiles > 0 || data?.bulkPageFiles) {
      setImagesToShow([...data?.memoryFiles, ...data?.bulkPageFiles]);
    }
    dispatch(fetchSingleTimeline(id));
  }, [data]);

  // get photo

  const openPhoto = (id: any) => {
    setOpenPopup(true);
    setGetPhoto(id);
  };

  // create update

  const dataToSend = {
    ...data, // Скопировать все существующие поля из объекта data
    timeline: id, // Добавить новое поле timeline
  };

  const handlerPost = () => {
    const id = data?.memoryId;
    const type = data?.action;
    const guestName = data?.guestName;

    if (guestName) {
      if (type === "update") {
        dispatch(fetchUpdatePanding({ id, ...data }));
        if (update === "Ok") {
          navigate("/Timeline");
        }
      } else if (type === "create") {
        dispatch(fetchCreatePanding({ ...dataToSend }));
        navigate("/Timeline");
      } else alert("Error");
    } else {
      if (type === "update") {
        dispatch(fetchUpdatePanding({ id, ...data }));
        navigate("/Timeline");
      } else if (type === "create") {
        dispatch(fetchCreatePanding({ ...dataToSend }));
        navigate("/Timeline");
      } else alert("Error");
    }
  };

  // delete

  const handlerDelete = () => {
    const actionId = data?.actionId;
    dispatch(fetchDeletePanding({ id, actionId }));
  };

  const setMemoryId = (id: string) => {
    setMemoryIdToken(id);
  };

  // redirect

  useEffect(() => {
    if (deleteAction === "OK") {
      navigate("/Timeline");
      dispatch(resetDeletePanding());
    }
  }, [deleteAction]);

  useEffect(() => {
    if (update === "OK") {
      navigate("/Timeline");
      dispatch(resetUpdatePanding());
    }
  }, [update]);

  return (
    <>
      <div className={styles.buttonPanel}></div>
      <div className={styles.wrapper}>
        <div className={styles.texts}>
          <h1>{data?.name}</h1>
          <div className={styles.head}>
            <Moment format="DD.MM.YYYY">{data?.date}</Moment> \\{" "}
            {data?.location}
          </div>
          <div className={styles.subHead}>{data?.participants?.join(", ")}</div>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />
          <div className={styles.wrapperControll}>
            <Button title="Approve" action={handlerPost} />
            <Button
              title="Edit"
              action={() => {
                navigate("/Edit-panding");
                setMemoryId(data?.memoryId);
              }}
              sx={{ marginLeft: "20px" }}
            />
            <Button
              title="Decline"
              action={handlerDelete}
              sx={{ marginLeft: "20px" }}
            />
            <p>{errors || erro}</p>
          </div>
        </div>
        <div className={styles.picture}>
          <Masonry
            breakpointCols={{
              default: 2,
              700: 1,
            }}
            className={styles.myMasonryGrid}
            columnClassName={styles.myMasonryGridColumn}
          >
            {imagesToShow?.map((path: any, index: number) => (
              <div
                key={index}
                className={
                  path?.metadata
                    ? styles.myMasonryGridColumnRed
                    : styles.myMasonryGridColumn
                }
              >
                {path?.path?.includes("mp4") ||
                path?.path?.includes("MP4") ||
                path?.path?.includes("mov") ||
                path?.path?.includes("MOV") ||
                path?.path?.includes("mkv") ||
                path?.path?.includes("MKV") ? (
                  <>
                    <video
                      className={styles.images}
                      onClick={() => openPhoto(path)}
                      src={`https://storage.googleapis.com/bucket_for_h4m/${path.path}`}
                      controls={false}
                    />
                    <button
                      onClick={() => openPhoto(path)}
                      className={styles.playButton}
                    ></button>
                  </>
                ) : (
                  <img
                    className={styles.images}
                    src={`https://storage.googleapis.com/bucket_for_h4m/${path.path}`}
                    alt=""
                    onClick={() => openPhoto(path)}
                  />
                )}
              </div>
            ))}
          </Masonry>
        </div>
      </div>
      <div className={styles.bottom}>
        <Link to="/Panding">
          ({dataSingle?.timeline?.participantsActions?.length}) Pending memories
        </Link>
        <p>
          {data?.action} by {data?.author}
        </p>
      </div>
    </>
  );
};
