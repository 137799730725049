import { Backgrounds, NavigateBar, PopupPicture } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { useState } from "react";
import { Books } from "./ui/Books";
import { PopupShare } from "../Panding/ui/PopupShare";
import { FiltersBar } from "../../components/Sidebars/Filters-bar";

export const Timeline = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});

  const [openShare, setOpenShare] = useState(false);
  const [getMemoryId, setGetMemoryID] = useState({});

  return (
    <Layout>
      <FiltersBar />
      <Backgrounds background={Background} logo={Download}>
        <Books setOpenPopup={setOpenPopup} setOpenShare={setOpenShare} setGetPhoto={setGetPhoto} setGetMemoryID={setGetMemoryID} />
        {openPopup && <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} />}
        {openShare && <PopupShare getMemoryId={getMemoryId} setOpenShare={setOpenShare} />}
      </Backgrounds>
    </Layout>
  );
};
