import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdateTimeline } from "../../../api/fetchUpdateTimeline";

interface UserState {
  update: string;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  update: "",
  isLoading: false,
  error: "",
};

export const updateTimelineReducer = createSlice({
  name: "updateTimeline",
  initialState,
  reducers: {
    resetUpdateTimelineData: (state) => {
      state.update = initialState.update;
      state.error = initialState.error;
    },
  },
  extraReducers: {
    [fetchUpdateTimeline.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = "";
      state.update = action.payload;
    },
    [fetchUpdateTimeline.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchUpdateTimeline.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { resetUpdateTimelineData } = updateTimelineReducer.actions;

export default updateTimelineReducer.reducer;
