import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdateDocument } from "../../../api/fetchUpdateDocument";

export interface IResult {
  id: null;
}

interface UserState {
  update: IResult;
  Loading: boolean;
  errors: string;
}

const initialState: UserState = {
  update: {
    id: null,
  },
  Loading: false,
  errors: "",
};

export const updateDocumentReducer = createSlice({
  name: "updateDocument",
  initialState,
  reducers: {
    resetUpdateDocument: (state) => {
      state.update = initialState.update;
    },
  },
  extraReducers: {
    [fetchUpdateDocument.fulfilled.type]: (
      state,
      action: PayloadAction<IResult>
    ) => {
      state.Loading = false;
      state.errors = "";
      state.update = action.payload;
    },
    [fetchUpdateDocument.pending.type]: (state) => {
      state.Loading = true;
    },
    [fetchUpdateDocument.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.Loading = false;
      state.errors = action.payload;
    },
  },
});

export const { resetUpdateDocument } = updateDocumentReducer.actions;

export default updateDocumentReducer.reducer;
