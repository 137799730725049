import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdatePhotoTimeline } from "../../../api/fetchUpdatePhotoTimeline";

interface UserState {
  responseData: string;
  load: boolean;
  err: string;
}

const initialState: UserState = {
  responseData: "",
  load: false,
  err: "",
};

export const updatePhotoTimelineReducer = createSlice({
  name: "updatePhotoTimeline",
  initialState,
  reducers: {
    resetUpdateTimelineFiles: (state) => {
      state.responseData = initialState.responseData;
      state.err = initialState.err;
    },
  },
  extraReducers: {
    [fetchUpdatePhotoTimeline.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.load = false;
      state.err = "";
      state.responseData = action.payload;
    },
    [fetchUpdatePhotoTimeline.pending.type]: (state) => {
      state.load = true;
    },
    [fetchUpdatePhotoTimeline.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.load = false;
      state.err = action.payload;
    },
  },
});

export const { resetUpdateTimelineFiles } = updatePhotoTimelineReducer.actions;

export default updatePhotoTimelineReducer.reducer;
