import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdateMemoGuest } from "../../../api/fetchUpdateMemoGuest";

export interface IMemoryPhotos {
  path: string;
  metadata?: {
    address: string;
    date: string;
  }[];
}

export interface IResult {
  id: null;
}

export interface IMemory {
  bulkPageFiles: IMemoryPhotos[];
  files: [];
}

interface UserState {
  update: IMemory;
  result: IResult;
  Loading: boolean;
  errors: null | any;
}

const initialState: UserState = {
  update: {
    bulkPageFiles: [],
    files: [],
  },
  result: {
    id: null,
  },
  Loading: false,
  errors: null,
};

export const updateMemoGuestReducer = createSlice({
  name: "updateMemoGuest",
  initialState,
  reducers: {
    resetUpdateMemoryData: (state) => {
      state.update = initialState.update;
      state.result = initialState.result;
    },
    addBulkPageFiles: (state, action: PayloadAction<IMemoryPhotos[]>) => {
      state.update.bulkPageFiles = action.payload;
    },
    addFiles: (state, action: PayloadAction<any>) => {
      state.update.files = action.payload;
    },
  },
  extraReducers: {
    [fetchUpdateMemoGuest.fulfilled.type]: (
      state,
      action: PayloadAction<IResult>
    ) => {
      state.Loading = false;
      state.errors = null;
      state.result = action.payload;
    },
    [fetchUpdateMemoGuest.pending.type]: (state) => {
      state.Loading = true;
    },
    [fetchUpdateMemoGuest.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.Loading = false;
      state.errors = action.payload;
    },
  },
});

export const { resetUpdateMemoryData, addBulkPageFiles, addFiles } =
  updateMemoGuestReducer.actions;

export default updateMemoGuestReducer.reducer;
