import { FC, useRef, useState } from "react";
import styles from "./MultiUpload.module.scss";
import { useAppDispatch } from "../../../../hooks/redux";
import { addFiles } from "../../../../store/feature/memory/createMemories";

interface IMultiUpload {
  setImage: any;
  image: any;
}

export const MultiUpload: FC<IMultiUpload> = ({ setImage, image }) => {
  const dispatch = useAppDispatch();
  const [imageUrls, setImageUrls] = useState<any>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileList = Array.from(files);
      setImage(fileList);
      const urls = fileList.map((file) => URL.createObjectURL(file));
      setImageUrls(urls);
      dispatch(addFiles(fileList));
    }
  };

  return (
    <div className={styles.wrappers}>
      <div className={styles.blocks}>
        <input
          type="file"
          name=""
          id="upload"
          onChange={handleImageChange}
          ref={fileInputRef}
          multiple
        />
        <img src={imageUrls[0]} alt="" />
        <label htmlFor="upload">
          <b>Add picture</b>
        </label>
      </div>
    </div>
  );
};
