import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";

export const fetchGetDocument = createAsyncThunk(
  "getDocument/fetchGetDocument",
  async (id: string | null, thunkAPI) => {
    try {
      const response = await axiosConfig.get<string | null>(`/timeline/${id}/getDocuments`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed get document"
      );
    }
  }
);
