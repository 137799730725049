import styles from "./Filters.module.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { RootState } from "../../../../store";
import { getNameToken, getTimelineIdToken } from "../../../../hooks/localstorage";
import { fetchSingleTimeline } from "../../../../store/api/fetchSingleTimeline";
import { fetchGetMemories } from "../../../../store/api/fetchGetMemories";
import { FilterName, resetFilters } from "../../../../store/feature/filters/filterHub";
import { SelectInput } from "../../../../components/Sidebars/Filters-bar/ui";
import rotate from "../../../../assets/icons/rotate-icon.png";

export const FiltersBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { dataSingle } = useAppSelector((state: RootState) => state.singleTimelineReducer);
  const { data } = useAppSelector((state) => state.getDocumentReducer);
  const { comment } = useAppSelector((state) => state.commentsReducer);
  const id = getTimelineIdToken();
  const profile = getNameToken();
  const [selectedName, setSelectedName] = useState<string>("");
  const [name, setName] = useState<any>([]);

  useEffect(() => {
    dispatch(fetchSingleTimeline(id));
    dispatch(fetchGetMemories(id));
  }, [dispatch, comment]);

  useEffect(() => {
    setName(data.map((item) => item?.name));
  }, [dataSingle, data]);

  // Filters handler

  const handleNameChange = (value: string) => {
    setSelectedName(value);
    dispatch(FilterName(value));
  };

  // clear

  const clearfFilters = () => {
    dispatch(resetFilters());
    window.location.reload();
  };

  useEffect(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  return (
    <div className={styles.wrapper} style={{ width: "650px" }}>
      <div className={styles.head}>
        <div className={styles.burgerColumn}>
          <div className={styles.burgerLine}></div>
          <div className={styles.burgerLine}></div>
          <div className={styles.burgerLine}></div>
        </div>
        <Link to="/" className={styles.name}>
          {profile}
        </Link>
      </div>

      <div className={styles.middle}>
        <div className={styles.row}>
          <p>Name</p>
          <SelectInput
            options={name}
            value={selectedName}
            placeholder="Select a name"
            onChange={handleNameChange}
            cv
          />
        </div>

        <div className={styles.clear} onClick={clearfFilters}>
          <p>Reset filters</p>
          <img src={rotate} alt="reset" />
        </div>

      </div>

    </div>
  );
};
