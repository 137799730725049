import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchComments } from "../../api/fetchComments";

interface UserState {
  comment: string
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  comment: "",
  isLoading: false,
  error: "",
};

export const commentsReducer = createSlice({
  name: "comments",
  initialState,
  reducers: {
    resetComments: (state) => {
      state.comment = initialState.comment;
      state.error = initialState.error;
    },
  },
  extraReducers: {
    [fetchComments.fulfilled.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = "";
      state.comment = action.payload;
    },
    [fetchComments.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchComments.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { resetComments } = commentsReducer.actions;

export default commentsReducer.reducer;
