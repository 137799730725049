import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";

export const fetchSingleTimeNonAuth = createAsyncThunk(
  "singleTimeNonAuth/fetchSingleTimeNonAuth",
  async (id: string | null, thunkAPI) => {
    try {
      const response = await axiosConfig.get<string>(`/timeline/${id}/share/`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed"
      );
    }
  }
);
