import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITimeline } from "./types";
import { fetchSingleTimeNonAuth } from "../../../api/fetchSingleTimeNonAuth";

interface UserState {
  dataSingle: ITimeline | null;
  isLoad: boolean;
  errors: string;
}

const initialState: UserState = {
  dataSingle: null,
  isLoad: false,
  errors: "",
};

export const singleTimeNonAuthReducer = createSlice({
  name: "singleTimeNonAuth",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSingleTimeNonAuth.fulfilled.type]: (state, action: PayloadAction<ITimeline>) => {
      state.isLoad = false;
      state.errors = "";
      state.dataSingle = action.payload;
    },
    [fetchSingleTimeNonAuth.pending.type]: (state) => {
      state.isLoad = true;
    },
    [fetchSingleTimeNonAuth.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoad = false;
      state.errors = action.payload;
    },
  },
});

export default singleTimeNonAuthReducer.reducer;
