import styles from "./NonAuthScattered.module.scss";
import { Backgrounds, Button, NavigateBar, PopupPicture } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useEffect, useState } from "react";
import { getNameToken, getTimelineIdToken, setStepPicture } from "../../hooks/localstorage";
import { TailSpin } from "react-loader-spinner";
import { NonAuthPicturePlace } from "./ui/NonAuthPicturePlace";
import { fetchFilesNonAuth } from "../../store/api/fetchFilesNonAuth";
import { NonAuthBar } from "../../components/Sidebars/NonAuth-bar";

export const NonAuthScattered = () => {
  const dispatch = useAppDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [openGridPicture, setOpenGridPicture] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});
  const { files, isLoading } = useAppSelector((state) => state.filesNonAuthReducer);

  const names = getNameToken();
  const timelineSingle = getTimelineIdToken();

  useEffect(() => {
    dispatch(fetchFilesNonAuth({ timelineSingle, names }));
  }, [dispatch]);

  const redirectCreate = () => {
    window.history.back();
    setStepPicture("picture");
  };

  return (
    <Layout>
      <NonAuthBar />
      <Backgrounds background={Background} logo={Download}>
        <div className={styles.wrapper}>
          {isLoading ? (
            <div className={styles.loader}>
              <TailSpin
                height="50"
                width="50"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
              />
            </div>
          ) : (
            <NonAuthPicturePlace
              setOpenPopup={setOpenPopup}
              setGetPhoto={setGetPhoto}
              data={files}
              openGridPicture={openGridPicture}
              setOpenGridPicture={setOpenGridPicture}
            />
          )}
        </div>
        {openPopup && (
          <PopupPicture
            getPhoto={getPhoto}
            setOpenPopup={setOpenPopup}
            scattered
          />
        )}
        <div className={styles.buttons}>
          <Button
            sx={{ marginRight: "40px" }}
            title="Build memory"
            action={redirectCreate}
          />
          <Button title="View all" action={() => setOpenGridPicture(true)} />
        </div>
      </Backgrounds>
    </Layout>
  );
};
