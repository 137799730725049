import styles from "./FiltersBar.module.scss";
import { useState, useEffect } from "react";
import { getNameToken, getTimelineIdToken } from "../../../hooks/localstorage";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { fetchSingleTimeline } from "../../../store/api/fetchSingleTimeline";
import { SelectInput } from "./ui";
import { fetchGetMemories } from "../../../store/api/fetchGetMemories";
import moment from "moment";
import {
  FilterCategory,
  FilterDate,
  FilterLocation,
  FilterParticipant,
  resetFilters,
} from "../../../store/feature/filters/filterHub";
import { Button, TextField } from "../..";
import { fetchComments } from "../../../store/api/fetchComments";
import { RootState } from "../../../store";
import { resetComments } from "../../../store/feature/comments";
import { Link, NavLink } from "react-router-dom";
import rotate from "../../../assets/icons/rotate-icon.png";

import Profile from "../../../assets/menu/profile.png";
import Plus from "../../../assets/menu/plus.png";
import Change from "../../../assets/menu/change.png";
import Scattered from "../../../assets/menu/scattered.png";
import Settins from "../../../assets/menu/settins.png";
import Message from "../../../assets/menu/message.png";
import Create from "../../../assets/menu/create.png";
import Logout from "../../../assets/menu/log-out.png";

export const FiltersBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { dataSingle } = useAppSelector(
    (state: RootState) => state.singleTimelineReducer
  );
  const { data } = useAppSelector((state) => state.getMemoriesReducer);
  const { comment } = useAppSelector((state) => state.commentsReducer);
  const id = getTimelineIdToken();
  const profile = getNameToken();
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedParticipant, setSelectedParticipant] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [categories, setCategories] = useState<any>([]);
  const [participants, setParticipants] = useState<any>([]);
  const [location, setLocation] = useState<any>([]);
  const [date, setDate] = useState<any>([]);
  const [short, setShort] = useState(false);

  useEffect(() => {
    dispatch(fetchSingleTimeline(id));
    dispatch(fetchGetMemories(id));
  }, [dispatch, comment]);

  useEffect(() => {
    setCategories(dataSingle?.timeline?.categories);
    setParticipants(dataSingle?.timeline?.participants);

    const uniqueLocations = data
      .map((item) => item?.location)
      .filter(
        (location, index, array) =>
          location && array.indexOf(location) === index
      );
    setLocation(uniqueLocations);

    // formated date
    const uniqueDates: any = data
      .map((item) => item?.date && moment.utc(item.date).format("DD-MM-YYYY"))
      .filter((date, index, array) => date && array.indexOf(date) === index);
    setDate(uniqueDates);
  }, [dataSingle, data]);

  // Filters handler

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
    dispatch(FilterCategory(value));
  };

  const handleParticipantChange = (value: string) => {
    setSelectedParticipant(value);
    dispatch(FilterParticipant(value));
  };

  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
    dispatch(FilterLocation(value));
  };

  const handleDateChange = (value: string) => {
    setSelectedDate(value);
    dispatch(FilterDate(value));
  };

  // clear

  const clearfFilters = () => {
    dispatch(resetFilters());
    window.location.reload();
  };

  // post comment

  const postComment = async () => {
    await dispatch(fetchComments({ id, text }));
    dispatch(resetComments());
  };

  useEffect(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  return (
    <div
      className={styles.wrapper}
      style={short ? { width: "130px" } : { width: "650px" }}
    >
      <div className={styles.head}>
        <div className={styles.burgerColumn} onClick={() => setShort(!short)}>
          <div className={styles.burgerLine}></div>
          <div className={styles.burgerLine}></div>
          <div className={styles.burgerLine}></div>
        </div>
        <Link to="/" className={styles.name}>
          {!short && profile}
        </Link>
      </div>

      {short ? (
        <div className={styles.hiddenMenu}>
          <div className={styles.linkWrapper}>
            <li>
              <NavLink to="/Timeline">
                <img src={Profile} alt="Profile" />
                {!short && <span>Watch TimeLine</span>}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Create-memory">
                <img src={Plus} alt="Plus" />
                {!short && <span>Create a Memory</span>}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Document">
                <img src={Change} alt="Change" />
                {!short && <span>Important Document</span>}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Create-document">
                <img src={Plus} alt="Plus" />
                {!short && <span>Create an Important Document</span>}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Panding">
                <img src={Message} alt="Message" />
                {!short && (
                  <span>
                    Invitations & Pending Memories(
                    {dataSingle?.timeline?.participantsActions?.length})
                  </span>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Scattered">
                <img src={Scattered} alt="Scattered" />
                {!short && <span>Scattered page</span>}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Settings-timeline">
                <img src={Settins} alt="Settins" />
                {!short && <span>TimeLine Settings</span>}
              </NavLink>
            </li>
          </div>

          <div className={styles.comments}>
            {dataSingle?.timeline?.comments?.map((item: any) => (
              <li className={styles.bottomContainer}>
                <div className={styles.bottomGroup}>
                  <img
                    src={`https://storage.googleapis.com/bucket_for_h4m/${item?.avatar}`}
                    alt=""
                  />
                </div>
              </li>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className={styles.middle}>
            <div className={styles.row}>
              <p>Type</p>
              <SelectInput
                options={categories}
                value={selectedCategory}
                placeholder="Select a category"
                onChange={handleCategoryChange}
                cv
              />
            </div>

            <div className={styles.row}>
              <p>Location</p>
              <SelectInput
                options={location}
                value={selectedLocation}
                placeholder="Select a category"
                onChange={handleLocationChange}
                cv
              />
            </div>

            <div className={styles.row}>
              <p>Date</p>
              <SelectInput
                options={date}
                value={selectedDate}
                placeholder="Select a category"
                onChange={handleDateChange}
                cv
              />
            </div>

            <div className={styles.row}>
              <p>Participants</p>
              <SelectInput
                options={participants}
                value={selectedParticipant}
                placeholder="Select a participant"
                onChange={handleParticipantChange}
              />
            </div>

            <div className={styles.clear} onClick={clearfFilters}>
              <p>Reset filters</p>
              <img src={rotate} alt="reset" />
            </div>
          </div>

          <div className={styles.bottom}>
            <ul>
              {dataSingle?.timeline?.comments?.map((item: any) => (
                <li className={styles.bottomContainer}>
                  <div className={styles.bottomGroup}>
                    <img
                      src={`https://storage.googleapis.com/bucket_for_h4m/${item?.avatar}`}
                      alt=""
                    />
                    <p>{item?.author}:</p>
                  </div>
                  <span>{item?.text}</span>
                </li>
              ))}
            </ul>
            <div className={styles.panelComment}>
              <p>Add comment :</p>
              <TextField
                label={""}
                type="text"
                value={text}
                setValue={setText}
                sx={{ width: "300px" }}
              />
              <Button
                title="Send"
                action={postComment}
                sx={{ marginTop: "20px" }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
