import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IParticipants } from "./types";
import { fetchGetParticipants } from "../../../api/fetchGetParticipants";

interface UserState {
  data: IParticipants[];
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: [],
  isLoading: false,
  error: "",
};

export const getParticipantsReducer = createSlice({
  name: "getParticipants",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGetParticipants.fulfilled.type]: (
      state,
      action: PayloadAction<IParticipants[]>
    ) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchGetParticipants.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchGetParticipants.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default getParticipantsReducer.reducer;
