// auth

export function setAuthToken(token: string) {
  sessionStorage.setItem("JWT_TOKEN", token);
}

export function getAuthToken(): string | null {
  return sessionStorage.getItem("JWT_TOKEN");
}

// name

export function getNameToken(): string | null {
  return sessionStorage.getItem("name");
}

export function setNameToken(name: string) {
  sessionStorage.setItem("name", name);
}

// tineline name

export function setTimelineNameToken(name: string) {
  sessionStorage.setItem("timeline_name", name);
}

export function getTimelineNameToken(): string | null {
  return sessionStorage.getItem("timeline_name");
}

// timeline id

export function setTimelineIdToken(name: string) {
  sessionStorage.setItem("timeline_id", name);
}

export function getTimelineIdToken(): string | null {
  return sessionStorage.getItem("timeline_id");
}

// memory id

export function setMemoryIdToken(name: string) {
  sessionStorage.setItem("memory_id", name);
}

export function getMemoryIdToken(): string | null {
  return sessionStorage.getItem("memory_id");
}

// step picture id

export function setStepPicture(name: string) {
  sessionStorage.setItem("picture", name);
}

export function getStepPicture(): string | null {
  return sessionStorage.getItem("picture");
}

// super_TOKEN

export function setSuperUserToken(name: string) {
  sessionStorage.setItem("super_TOKEN", name);
}

export function getSuperUserToken(): string | null {
  return sessionStorage.getItem("super_TOKEN");
}

