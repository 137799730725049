import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuth } from "./types";
import { fetchAuth } from "../../api/fetchAuth";
import { setAuthToken, setNameToken, setSuperUserToken } from "../../../hooks/localstorage";

interface UserState {
  data: IAuth | null;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: null,
  isLoading: false,
  error: "",
};

export const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAuth.fulfilled.type]: (state, action: PayloadAction<IAuth & { JWT_TOKEN: string }>) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
      setAuthToken(action.payload.JWT_TOKEN);
      setNameToken(action.payload.name);
      setSuperUserToken(action.payload.superAdmin);
    },
    [fetchAuth.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchAuth.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default authReducer.reducer;
