import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IComment } from "./types";

export const fetchFileMetadata = createAsyncThunk(
  "fileMetadata/fetchFileMetadata",
  async ({ id, ...body }: { id: string | null } & IComment, thunkAPI) => {
    try {
      const response = await axiosConfig.post<IComment>(
        `/timeline/updateFileMetadata/${id}`,
        body
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed add fileMetadata"
      );
    }
  }
);
