import styles from "./ResultContainer.module.scss";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  DateField,
  FieldDescription,
  MultiTextSelect,
  SelectText,
  SmallRadio,
  TextFieldLine,
} from "../../../../components";
import {
  IMemory,
  resetCreateMemoryData,
} from "../../../../store/feature/memory/createMemories";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { fetchCreateMemories } from "../../../../store/api/fetchCreateMemories";
import { TailSpin } from "react-loader-spinner";
import { getTimelineIdToken } from "../../../../hooks/localstorage";
import { useNavigate } from "react-router-dom";
import { fetchUpdateFilesMemoriess } from "../../../../store/api/fetchUpdateFilesMemories";
import { Carousel } from "../Carousel";
import { resetUpdateMemoryFiless } from "../../../../store/feature/memory/updateFilesMemoriess";
import { UploadImages } from "../UploadImages";

interface IResultContainer {
  data?: IMemory;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
  error: string;
  loading?: boolean;
  response: {
    id: null;
  };
  setClose: (value: number) => void;
}

export const ResultContainer: FC<IResultContainer> = ({
  data,
  setOpenPopup,
  setGetPhoto,
  error,
  loading,
  response,
  setClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = getTimelineIdToken();

  const [cv, setCv] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [date, setDate] = useState<any>("");
  const [location, setLocation] = useState<string>("");
  const [participants, setParticipants] = useState<any>([]);
  const [description, setDescription] = useState<string>("");
  const [privacy, setPrivacy] = useState<string>("");
  const [bulkPageFiles, setBulkPageFiles] = useState<any>([]);
  const [visualCarousel, setVisualCarousel] = useState<any>([]);
  const [additionalPicture, setAdditionalPicture] = useState<any>([]);
  const [files, setFiles] = useState<[]>([]);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const { responseData, Load, err } = useAppSelector(
    (state) => state.updateFilesMemoriessReducer
  );

  useEffect(() => {
    if (files != null) {
      setVisualCarousel([...bulkPageFiles, ...files]);
    }
  }, [files]);

  useEffect(() => {
    if (data) {
      const {
        name,
        location,
        bulkPageFiles,
        date,
        description,
        participants,
        files,
      } = data;
      setName(name);
      setLocation(location);
      setParticipants(participants);
      setDate(date);
      setDescription(description);
      setParticipants(participants);
      setBulkPageFiles(bulkPageFiles);
      setFiles(files);
    }
  }, [data]);

  useEffect(() => {
    if (response.id !== null && files.length <= 0) {
      dispatch(resetCreateMemoryData());
      navigate("/Timeline");
    } else if (response.id !== null && files.length > 0 && !isRequestSent) {
      const formData: any = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("timeline", id || "");
      dispatch(fetchUpdateFilesMemoriess({ formData, ids: response.id || "" }));
      setIsRequestSent(true);
    } else if (responseData === "OK" && isRequestSent) {
      dispatch(resetCreateMemoryData());
      dispatch(resetUpdateMemoryFiless());
      navigate("/Timeline");
    }
  }, [response.id, responseData, files, isRequestSent, navigate, dispatch, id]);

  const handlerCreate = () => {
    dispatch(
      fetchCreateMemories({
        cv,
        name,
        date,
        location,
        participants,
        description,
        privacy,
        bulkPageFiles: additionalPicture.length > 0 ? additionalPicture : bulkPageFiles,
        timeline: id,
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        {loading && (
          <TailSpin
            height="50"
            width="50"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        )}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.texts}>
          <div className={styles.column}>
            <SelectText
              label="Master headlines(CV) :"
              value={cv}
              setValue={setCv}
              sx={{ width: "300px" }}
            />
            <TextFieldLine
              label="Name :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
            <DateField
              label="Date :"
              width="300px"
              value={date}
              setValue={setDate}
            />
            <TextFieldLine
              label="Location :"
              type="text"
              value={location}
              setValue={setLocation}
              sx={{ width: "300px" }}
            />
            <MultiTextSelect
              label="Participants :"
              value={participants}
              setValue={setParticipants}
              sx={{ width: "300px" }}
            />
            <FieldDescription
              label="Description :"
              value={description}
              setValue={setDescription}
              sx={{ width: "300px" }}
            />
          </div>
          <div className={styles.radio}>
            <SmallRadio setPrivacy={setPrivacy} privacy={privacy} />
          </div>
          <div className={styles.carousel}>
            <p>Additional media :</p>
            <Carousel
              setOpenPopup={setOpenPopup}
              setGetPhoto={setGetPhoto}
              picture={visualCarousel}
              setPicture={setVisualCarousel}
              setAdditionalPicture={setAdditionalPicture}
            />
          </div>
        </div>
        <div className={styles.redirect}>
          <UploadImages
            image={files}
            setImage={setFiles}
            preview={visualCarousel}
          />
          <p onClick={() => setClose(1)}></p>
        </div>
      </div>
      <p className={styles.error}>{error || err}</p>
      <Button
        sx={{ display: "flex", justifyContent: "right" }}
        title="Create"
        action={handlerCreate}
      />
    </div>
  );
};
