import { useRef, useState } from "react";
import styles from "./MultiUpload.module.scss";
import { useAppDispatch } from "../../../../../hooks/redux";
import { addFiles } from "../../../../../store/feature/memory/updateMemoGuest";

export const MultiUpload = () => {
  const dispatch = useAppDispatch();
  const [imageUrls, setImageUrls] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<any>([]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileList = Array.from(files);
      setImage(fileList);
      const urls = fileList.map((file) => URL.createObjectURL(file));
      setImageUrls(urls);
      dispatch(addFiles(fileList));
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.block}>
          <input
            type="file"
            name=""
            id="upload"
            onChange={handleImageChange}
            ref={fileInputRef}
            multiple
          />
        </div>
      </div>
    </div>
  );
};
