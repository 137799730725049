import styles from "./SigninBar.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { fetchAuth } from "../../../store/api/fetchAuth";
import { Link, useNavigate } from "react-router-dom";
import { Button, TextField } from "../..";
import { TailSpin } from "react-loader-spinner";

export const SigninBar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useAppSelector((state) => state.authReducer);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handlerClick = () => {
    dispatch(fetchAuth({ email, password }));
  };

  useEffect(() => {
    const token = sessionStorage.getItem("JWT_TOKEN");
    if (token) {
      navigate("/");
    }
  }, [data, error]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <Link to="/Sign-in">Log in</Link>
        {/* <Link to="/Sign-up">Sign up</Link> */}
      </div>
      <div className={styles.body}>
        <TextField
          setValue={setEmail}
          value={email}
          type="text"
          label="Email:"
          sx={{ width: "80%" }}
        />
        <TextField
          setValue={setPassword}
          value={password}
          type="password"
          label="Password:"
          sx={{ marginTop: "30px", width: "80%" }}
        />
        <div className={styles.error}>
          <span>{error && error}</span>
        </div>
      </div>
      <div className={styles.footer}>
        {isLoading ? (
          <div className={styles.loader}>
            <TailSpin
              height="30"
              width="30"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              visible={true}
            />
          </div>
        ) : (
          <Button
            action={handlerClick}
            title="Sign in"
            sx={{ textAlign: "right" }}
          />
        )}
      </div>
    </div>
  );
};
