import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDeleteTimeline } from "../../../api/fetchDeleteTimeline";

interface UserState {
  result: string;
  loading: boolean;
  er: null | any;
}

const initialState: UserState = {
  result: "",
  loading: false,
  er: null,
};

export const deleteTimelineReducer = createSlice({
  name: "deleteTimeline",
  initialState,
  reducers: {
    resetDeleteTimelineData: (state) => {
      state.result = initialState.result;
      state.er = initialState.er;
    },
  },
  extraReducers: {
    [fetchDeleteTimeline.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.loading = false;
      state.er = null;
      state.result = action.payload;
    },
    [fetchDeleteTimeline.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchDeleteTimeline.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.loading = false;
      state.er = action.payload;
    },
  },
});

export const { resetDeleteTimelineData } = deleteTimelineReducer.actions;

export default deleteTimelineReducer.reducer;
