import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAddParticipant } from "../../../api/fetchAddParticipant";

interface UserState {
  data: string;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: "",
  isLoading: false,
  error: "",
};

export const addParticipantReducer = createSlice({
  name: "addParticipant",
  initialState,
  reducers: {
    resetAddParticipant: (state) => {
      state.data = initialState.data;
      state.error = initialState.error;
    },
  },
  extraReducers: {
    [fetchAddParticipant.fulfilled.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchAddParticipant.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchAddParticipant.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { resetAddParticipant } = addParticipantReducer.actions;

export default addParticipantReducer.reducer;
