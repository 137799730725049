import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IParticipants } from "./types";

export const fetchAddParticipant = createAsyncThunk(
  "addParticipant/fetchAddParticipant",
  async ({ id, ...body }: { id: string | null } & IParticipants, thunkAPI) => {
    try {

      const response = await axiosConfig.post<IParticipants>(
        `timeline/addParticipant/${id}`,
        body,
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "app participants failed"
      );
    }
  }
);
