import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdateFiles } from "../../../api/fetchUpdateFiles";

interface UserState {
  data: string;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: "",
  isLoading: false,
  error: "",
};

export const updateFilesReducer = createSlice({
  name: "updateFiles",
  initialState,
  reducers: {
    resetFiles: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [fetchUpdateFiles.fulfilled.type]: (
      state,
      action: PayloadAction<"">
    ) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchUpdateFiles.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchUpdateFiles.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { resetFiles } = updateFilesReducer.actions;

export default updateFilesReducer.reducer;
