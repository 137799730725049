import { Backgrounds, SigninBar } from "../../components";
import Background from "../../assets/background/basic.png";
import { Layout } from "../../utils/Layout";

export const Signin = () => {

  return (
    <Layout>
      <SigninBar />
      <Backgrounds background={Background} />
    </Layout>
  );
};
