import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { CreateDocument, CreateMemory, CreateTimeLine, DifferentTimeLine, Document, EditDocument, EditTimeline, Home, Panding, Scattered, SettingsTimeLine, Signin, Signup, Timeline, Upload } from "./pages";
import { ProtectedRoute } from "./hooks/protectedRoute";
import { NonAuthTimeline } from "./pages/NonAuthTimeline";
import { NonAuthScattered } from "./pages/NonAuthScattered";
import { EditNonAuth } from "./pages/EditNonAuth";
import { PandingMemory } from "./pages/PandingMemory";
import { EditPanding } from "./pages/EditPanding";
import { NonAuthDocument } from "./pages/NonAuthDocument";

function App() {
  return (
    <>
      <Routes>
        <Route path="Sign-in" element={<Signin />}></Route>
        <Route path="Sign-up" element={<Signup />}></Route>

        <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/Timeline" element={<ProtectedRoute><Timeline /></ProtectedRoute>} />
        <Route path="/Edit-memory" element={<ProtectedRoute><EditTimeline /></ProtectedRoute>} />
        <Route path="/Create-memory" element={<ProtectedRoute><CreateMemory /></ProtectedRoute>} />
        <Route path="/Document" element={<ProtectedRoute><Document /></ProtectedRoute>} />
        <Route path="/Edit-document" element={<ProtectedRoute><EditDocument /></ProtectedRoute>} />
        <Route path="/Create-document" element={<ProtectedRoute><CreateDocument/></ProtectedRoute>} />
        <Route path="/Panding" element={<ProtectedRoute><Panding/></ProtectedRoute>} />
        <Route path="/Panding-memory" element={<ProtectedRoute><PandingMemory /></ProtectedRoute>} />
        <Route path="/Edit-panding" element={<ProtectedRoute><EditPanding /></ProtectedRoute>} />
        <Route path="/Create-timeline" element={<ProtectedRoute><CreateTimeLine /></ProtectedRoute>} />
        <Route path="/Settings-timeline" element={<ProtectedRoute><SettingsTimeLine /></ProtectedRoute>} />
        <Route path="/Different-timeline" element={<ProtectedRoute><DifferentTimeLine /></ProtectedRoute>} />
        <Route path="/Scattered" element={<ProtectedRoute><Scattered /></ProtectedRoute>} />
        <Route path="/Scattered-upload" element={<ProtectedRoute><Upload /></ProtectedRoute>} />

        <Route path="/timeline/:type/:id/*" element={<NonAuthTimeline />} />
        <Route path="/document/:type/:id/*" element={<NonAuthDocument />} />
        <Route path="/Scattered-guest" element={<NonAuthScattered />} />
        <Route path="/Edit-guest" element={<EditNonAuth />} />
      </Routes>
    </>
  );
}

export default App;