import styles from "./TextFieldLine.module.scss";
import { Dispatch, FC, SetStateAction } from "react";
import MaskedInput from "react-input-mask";

interface ITextFieldLine {
  label: string;
  type: string;
  sx?: {};
  value: string | undefined;
  setValue: Dispatch<SetStateAction<string>>;
  useMask?: boolean;
}

export const TextFieldLine: FC<ITextFieldLine> = ({
  label,
  type,
  sx,
  value,
  setValue,
  useMask = false,
}) => {
  const inputElement = useMask ? (
    <MaskedInput
      mask="+972999999999"
      value={value}
      onChange={(e: { target: { value: SetStateAction<string> } }) =>
        setValue(e.target.value)
      }
      id={label}
      type={type}
      style={sx}
    />
  ) : (
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      id={label}
      type={type}
      style={sx}
    />
  );

  return (
    <div className={styles.wrapper}>
      <label htmlFor={label}>{label}</label>
      {inputElement}
    </div>
  );
};
