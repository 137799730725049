import styles from "./Scattered.module.scss";
import {
  Backgrounds,
  Button,
  NavigateBar,
  PopupPicture,
} from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useEffect, useState } from "react";
import { fetchFiles } from "../../store/api/fetchFiles";
import { getTimelineIdToken } from "../../hooks/localstorage";
import { PicturePlace } from "./ui/PicturePlace";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

export const Scattered = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [openGridPicture, setOpenGridPicture] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});
  const { data, isLoading } = useAppSelector((state) => state.filesReducer);
  const { result } = useAppSelector((state) => state.createMemoriesReducer);

  const id = getTimelineIdToken();

  useEffect(() => {
    dispatch(fetchFiles(id));
  }, [dispatch]);

  const redirectUpload = () => {
    navigate("/Scattered-upload");
  };

  const redirectCreate = () => {
    navigate("/Create-memory");
  };

  return (
    <Layout>
      <NavigateBar />
      <Backgrounds background={Background} logo={Download}>
        <div className={styles.wrapper}>
          {isLoading ? (
            <div className={styles.loader}>
              <TailSpin
                height="50"
                width="50"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
              />
            </div>
          ) : (
            <PicturePlace
              setOpenPopup={setOpenPopup}
              setGetPhoto={setGetPhoto}
              data={data}
              openGridPicture={openGridPicture}
              setOpenGridPicture={setOpenGridPicture}
              result={result}
            />
          )}
        </div>
        {openPopup && (
          <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} scattered />
        )}
        <div className={styles.buttons}>
          <Button
            sx={{ marginRight: "40px" }}
            title="Build memory"
            action={redirectCreate}
          />
          <Button
            sx={{ marginRight: "40px" }}
            title="Add photo"
            action={redirectUpload}
          />
          <Button title="View all" action={() => setOpenGridPicture(true)} />
        </div>
      </Backgrounds>
    </Layout>
  );
};
