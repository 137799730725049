import { Button, TextFieldLine } from "../../../../components";
import styles from "./PopupAddition.module.scss";
import { FC, useState } from "react";
import Minus from "../../../../assets/icons/minus.png";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { fetchAddParticipant } from "../../../../store/api/fetchAddParticipant";
import { getTimelineIdToken } from "../../../../hooks/localstorage";
import { SmallRadioParticipants } from "../SmallRadioParticipants";

interface IPopupAddition {
  setOpenAddition: (value: boolean) => void;
}

export const PopupAddition: FC<IPopupAddition> = ({ setOpenAddition }) => {
  const dispatch = useAppDispatch();
  const id = getTimelineIdToken();

  const { data, error } = useAppSelector((state) => state.addParticipantReducer);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [role, setRole] = useState<string>("");

  const sendParticipant = () => {
    dispatch(fetchAddParticipant({ name, email, phone, role, id }));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img
          className={styles.close}
          onClick={() => setOpenAddition(false)}
          src={Minus}
          alt="Minus"
        />
        <div className={styles.head}>
          <p>Do you want to add participants?</p>
          <h1>Participants</h1>
        </div>
        <div className={styles.column}>
          <TextFieldLine
            label="Add name :"
            type="text"
            value={name}
            setValue={setName}
            sx={{ width: "300px" }}
          />
          <TextFieldLine
            label="Email :"
            type="text"
            value={email}
            setValue={setEmail}
            sx={{ width: "300px" }}
          />
          <TextFieldLine
            label="Phone number :"
            type="text"
            value={phone}
            setValue={setPhone}
            sx={{ width: "300px" }}
            useMask={true}
          />
        </div>

        <div className={styles.messages}>
            {error ? (
              <p className={styles.error}>{error}</p>
            ) : data === "OK" ? (
              <p className={styles.done}>
                Participation has been added
              </p>
            ) : null}
          </div>

        <SmallRadioParticipants
          setValue={setRole}
          value={role}
          sx={{ marginTop: "40px" }}
        />
        <Button sx={{ marginLeft: "auto" }} title="Send" action={sendParticipant} />
      </div>
    </div>
  );
};
