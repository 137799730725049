import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdateParticipant } from "../../../api/fetchUpdateParticipant";

interface UserState {
  data: string;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: "",
  isLoading: false,
  error: "",
};

export const updateParticipantReducer = createSlice({
  name: "updateParticipant",
  initialState,
  reducers: {
    resetUpdateParticipant: (state) => {
      state.data = initialState.data;
      state.error = initialState.error;
    },
  },
  extraReducers: {
    [fetchUpdateParticipant.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchUpdateParticipant.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchUpdateParticipant.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { resetUpdateParticipant } = updateParticipantReducer.actions;

export default updateParticipantReducer.reducer;
