import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDocument } from "./types";
import { fetchSingleDocument } from "../../../api/fetchSingleDocument";

interface UserState {
  data: IDocument | null;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: null,
  isLoading: false,
  error: "",
};

export const singleDocumentReducer = createSlice({
  name: "singleDocument",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSingleDocument.fulfilled.type]: (state, action: PayloadAction<IDocument>) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchSingleDocument.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchSingleDocument.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default singleDocumentReducer.reducer;
