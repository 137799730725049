import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IFiles } from "./types";

export const fetchUpdatePhotoTimeline = createAsyncThunk<IFiles, [FormData, string], { rejectValue: string }>(
  "updatePhotoTimeline/fetchUpdatePhotoTimeline",
  async ([formData, id]: [FormData, string | undefined], thunkAPI) => {
    try {
      const response = await axiosConfig.post<IFiles>(`/timeline/updateMainPhoto/${id}`, formData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed upload main photo"
      );
    }
  }
);
