import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCreateMemories } from "../../../api/fetchCreateMemories";

export interface IMemoryPhotos {
  path: string;
  metadata?: {
    address: string;
    date: string;
  }[];
}

export interface IResult {
  id: null;
}

export interface IMemory {
  bulkPageFiles: IMemoryPhotos[];
  files: [];
  name: string;
  location: string;
  date: string;
  description: string;
  participants: string[];
}

interface UserState {
  memory: IMemory;
  result: IResult;
  Loading: boolean;
  errors: null | any;
}

const initialState: UserState = {
  memory: {
    bulkPageFiles: [],
    files: [],
    name: "",
    location: "",
    date: "",
    description: "",
    participants: [],
  },
  result: {
    id: null
  },
  Loading: false,
  errors: null,
};

export const createMemoriesReducer = createSlice({
  name: "createMemories",
  initialState,
  reducers: {
    addMemory: (state, action: PayloadAction<IMemoryPhotos[]>) => {
      state.memory.bulkPageFiles = action.payload;
    },
    addFiles: (state, action: PayloadAction<any>) => {
      state.memory.files = action.payload;
    },
    addName: (state, action: PayloadAction<string>) => {
      state.memory.name = action.payload;
    },
    addLocation: (state, action: PayloadAction<string>) => {
      state.memory.location = action.payload;
    },
    addDate: (state, action: PayloadAction<string>) => {
      state.memory.date = action.payload;
    },
    addDescription: (state, action: PayloadAction<string>) => {
      state.memory.description = action.payload;
    },
    addParticipants: (state, action: PayloadAction<string[]>) => {
      state.memory.participants = action.payload;
    },
    resetCreateMemoryData: (state) => {
      state.memory = initialState.memory;
      state.result = initialState.result;
    },
  },
  extraReducers: {
    [fetchCreateMemories.fulfilled.type]: (
      state,
      action: PayloadAction<IResult>
    ) => {
      state.Loading = false;
      state.errors = null;
      state.result = action.payload;
    },
    [fetchCreateMemories.pending.type]: (state) => {
      state.Loading = true;
    },
    [fetchCreateMemories.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.Loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  addMemory,
  addFiles,
  addName,
  addLocation,
  addDate,
  addDescription,
  addParticipants,
  resetCreateMemoryData
} = createMemoriesReducer.actions;

export default createMemoriesReducer.reducer;
