import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdateMemories } from "../../../api/fetchUpdateMemories";

interface IResult {
  id: null;
}

interface UserState {
  updateSignUp: IResult;
  Loading: boolean;
  errors: null;
}

const initialState: UserState = {
  updateSignUp: {
    id: null,
  },
  Loading: false,
  errors: null,
};

export const updateMemoriesForSignupReducer = createSlice({
  name: "updateMemoriesForSignup",
  initialState,
  reducers: {
    resetUpdateMemoryDataSign: (state) => {
      state.updateSignUp = initialState.updateSignUp;
    },
  },
  extraReducers: {
    [fetchUpdateMemories.fulfilled.type]: (
      state,
      action: PayloadAction<IResult>
    ) => {
      state.Loading = false;
      state.errors = null;
      state.updateSignUp = action.payload;
    },
    [fetchUpdateMemories.pending.type]: (state) => {
      state.Loading = true;
    },
    [fetchUpdateMemories.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.Loading = false;
      state.errors = action.payload;
    },
  },
});

export const { resetUpdateMemoryDataSign } = updateMemoriesForSignupReducer.actions;

export default updateMemoriesForSignupReducer.reducer;
