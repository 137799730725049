import { useEffect, useState } from "react"
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { Layout } from "../../utils/Layout";
import { NonAuthBar } from "../../components/Sidebars/NonAuth-bar";
import { Backgrounds, PopupPicture } from "../../components";
import { Books } from "./Books";
import { useParams } from "react-router-dom";
import { setMemoryIdToken, setNameToken, setTimelineIdToken } from "../../hooks/localstorage";

export const NonAuthDocument = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});
  const { id, type, ...guest } = useParams();

  const [timeline] = useState<any>(type);
  const [memory] = useState<any>(id);
  const [names] = useState<any>(guest["*"]);

  useEffect(() => {
    setTimelineIdToken(timeline);
    setMemoryIdToken(memory);
    setNameToken(names);
  }, [id, type, guest]);
  
  
    return (
      <Layout>
        <NonAuthBar />
        <Backgrounds background={Background} logo={Download}>
          <Books setOpenPopup={setOpenPopup} setGetPhoto={setGetPhoto} />
          {openPopup && <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} />}
        </Backgrounds>
    </Layout>
    )
}