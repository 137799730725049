import { Dispatch, FC, SetStateAction } from "react";
import styles from "./DateFieldCustom.module.scss";
import { DatePicker } from "antd";
import moment from "moment";

interface IDateField {
  width: string;
  label?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  value?: any;
}

export const DateFieldCustom: FC<IDateField> = ({
  width,
  label,
  setValue,
  value,
}) => {

  return (
    <div className={styles.wrapper}>
      <DatePicker
        placeholder={value}
        style={{
          background: "none",
          color: "white",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderRadius: "0",
          width: width,
          height: "37px",
          boxShadow: "none",
          padding: "0",
          opacity: 1,
        }}
        onChange={(date, dateString) => {
          setValue && setValue(dateString);
        }}
      />
    </div>
  );
};
