import styles from "./Layout.module.scss"
import { FC } from "react";

interface ILayout {
  children: React.ReactNode;
}

export const Layout: FC<ILayout> = ({children}) => {
  return <div className={styles.wrapper}>{children}</div>;
};
