import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IParticipant } from "./types";

export const fetchUpdateParticipant = createAsyncThunk(
  "updateParticipant/fetchUpdateParticipant",
  async ({ id, previosEmail, ...body }: { id: string | null } & IParticipant, thunkAPI) => {
    try {

      const response = await axiosConfig.post<IParticipant>(
        `timeline/${id}/updateParticipant/${previosEmail}`,
        body,
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "paricipant update failed"
      );
    }
  }
);
