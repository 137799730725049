import { Dispatch, FC, SetStateAction } from "react";
import styles from "./DateField.module.scss";
import { DatePicker } from "antd";
import moment from "moment";

interface IDateField {
  width: string;
  label?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  value?: any;
  centerMode?: any;
}

export const DateField: FC<IDateField> = ({
  width,
  label,
  setValue,
  value,
  centerMode,
}) => {
  const gridTemplateColumns: any = centerMode ? null : "180px auto";

  return (
    <div
      className={styles.wrapper}
      style={{
        display: centerMode ? "block" : "grid",
        gridTemplateColumns,
      }}
    >
      <label>{label}</label>
      <DatePicker
        placeholder={value}
        style={{
          background: "none",
          color: "white",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "2px solid #000",
          borderRadius: "0",
          width: width,
          height: "37px",
          boxShadow: "none",
          padding: "0",
          transition: "opacity 0.3s ease",
          opacity: 1,
        }}
        onChange={(date, dateString) => {
          setValue && setValue(dateString);
        }}
      />
    </div>
  );
};
