import { Backgrounds, NavigateBar, PopupPicture } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { FC, useState } from "react";
import { Books } from "./ui/Book";
import { FiltersBar } from "./ui/FiltersDocument";
import { PopupShare } from "./ui/PopupShare";

export const Document: FC = () => {

  const [openPopup, setOpenPopup] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});

  const [openShare, setOpenShare] = useState(false);
  const [getMemoryId, setGetMemoryID] = useState({});

  return (
    <Layout>
      <FiltersBar />
      <Backgrounds background={Background} logo={Download}>
        <Books setOpenPopup={setOpenPopup} setGetPhoto={setGetPhoto} setOpenShare={setOpenShare} setGetMemoryID={setGetMemoryID} />
        {openPopup && <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} />}
        {openShare && <PopupShare getMemoryId={getMemoryId} setOpenShare={setOpenShare} />}
      </Backgrounds>
    </Layout>
  );
};
