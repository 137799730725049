import styles from "./NonAuthPicturePlace.module.scss";
import { FC, useState, useEffect, Dispatch, SetStateAction } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper";
import { addMemory } from "../../../../store/feature/memory/createMemories";
import { useAppDispatch } from "../../../../hooks/redux";
import { PopupGridPicture } from "../../../Scattered/ui/PopupGridPicture";
import { addBulkPageFiles } from "../../../../store/feature/memory/updateMemoGuest";

interface INonAuthPicturePlace {
  data: any;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setOpenGridPicture: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
  openGridPicture?: boolean;
}

export const NonAuthPicturePlace: FC<INonAuthPicturePlace> = ({
  data,
  setOpenPopup,
  setGetPhoto,
  openGridPicture,
  setOpenGridPicture,
}) => {
  const dispatch = useAppDispatch();
  const [selectedPictures, setSelectedPictures] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>(data);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);

  useEffect(() => {
    if (isInitialRender) {
      setFilteredData((prevState) =>
        prevState?.map((item) => ({
          ...item,
          top: `${Math.random() * 80}%`,
          left: `${Math.random() * 80}%`,
          rotate: Math.floor(Math.random() * 11) - 5,
          path: `${item.path}`,
        }))
      );
      setIsInitialRender(false);
    }
  }, [isInitialRender]);

  const handleSelectPicture = (item: any) => {
    setSelectedPictures((prevState) => [...prevState, item]);
    setFilteredData((prevState) =>
      prevState.filter((prevItem) => prevItem.uid !== item.uid)
    );
  };

  useEffect(() => {
    const formattedImage = selectedPictures.map((pic) =>
      pic.metadata
        ? { path: pic.path as string, metadata: pic.metadata }
        : { path: pic.path as string }
    );
    dispatch(addBulkPageFiles(formattedImage));
  }, [dispatch, selectedPictures]);

  const openPhoto = (id: any) => {
    setOpenPopup(true);
    setGetPhoto(id);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {filteredData?.map((item: any) => {
          const extension = item.path.split(".").pop()?.toLowerCase();
          if (
            extension === "mp4" ||
            extension === "MP4" ||
            extension === "mov" ||
            extension === "MOV" ||
            extension === "mkv" ||
            extension === "MKV"
          ) {
            return (
              <div
                className={styles.element}
                style={{
                  top: item.top,
                  left: item.left,
                  transform: `rotate(${item.rotate}deg)`,
                }}
                key={item.uid}
              >
                <video onClick={() => openPhoto(item)}>
                  <source
                    src={`https://storage.googleapis.com/bucket_for_h4m/${item.path}`}
                    type="video/mp4"
                  />
                </video>
                <span onClick={() => handleSelectPicture(item)}>+</span>
              </div>
            );
          } else {
            return (
              <div
                className={styles.element}
                style={{
                  top: item.top,
                  left: item.left,
                  transform: `rotate(${item.rotate}deg)`,
                }}
                key={item.uid}
              >
                <img
                  onClick={() => openPhoto(item)}
                  className={item?.metadata ? styles.red : ""}
                  src={`https://storage.googleapis.com/bucket_for_h4m/${item.path}`}
                />
                <span onClick={() => handleSelectPicture(item)}>+</span>
              </div>
            );
          }
        })}
      </div>

      <div className={styles.additional}>
        <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]}>
          {selectedPictures?.map((item: any) => {
            const extension = item.path.split(".").pop()?.toLowerCase();
            if (
              extension === "mp4" ||
              extension === "MP4" ||
              extension === "mov" ||
              extension === "MOV" ||
              extension === "mkv" ||
              extension === "MKV"
            ) {
              return (
                <div className={styles.element} key={item.uid}>
                  <SwiperSlide key={item.uid}>
                    <video
                      onClick={() => openPhoto(item)}
                      className={styles.picture}
                    >
                      <source
                        src={`https://storage.googleapis.com/bucket_for_h4m/${item.path}`}
                        type="video/mp4"
                      />
                    </video>
                  </SwiperSlide>
                </div>
              );
            } else {
              return (
                <div className={styles.element} key={item.uid}>
                  <SwiperSlide key={item.uid}>
                    <img
                      onClick={() => openPhoto(item)}
                      className={styles.picture}
                      src={`https://storage.googleapis.com/bucket_for_h4m/${item.path}`}
                    />
                  </SwiperSlide>
                </div>
              );
            }
          })}
        </Swiper>
      </div>
      {openGridPicture && (
        <PopupGridPicture
          data={data}
          setOpenGridPicture={setOpenGridPicture}
          setOpenPopup={setOpenPopup}
          setGetPhoto={setGetPhoto}
          handleSelectPicture={handleSelectPicture}
        />
      )}
    </div>
  );
};
