import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdatePanding } from "../../../api/fetchUpdatePanding";

interface UserState {
  update: string;
  Loading: boolean;
  erro: null;
}

const initialState: UserState = {
  update: "",
  Loading: false,
  erro: null,
};

export const updatePandingReducer = createSlice({
  name: "updatePanding",
  initialState,
  reducers: {
    resetUpdatePanding: (state) => {
      state.update = initialState.update;
      state.erro = initialState.erro;
    },
  },
  extraReducers: {
    [fetchUpdatePanding.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.Loading = false;
      state.erro = null;
      state.update = action.payload;
    },
    [fetchUpdatePanding.pending.type]: (state) => {
      state.Loading = true;
    },
    [fetchUpdatePanding.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.Loading = false;
      state.erro = action.payload;
    },
  },
});

export const { resetUpdatePanding } = updatePandingReducer.actions;

export default updatePandingReducer.reducer;
