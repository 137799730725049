import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IDocument } from "./types";

export const fetchCreateDocument = createAsyncThunk(
  "createDocument/fetchCreateDocument",
  async (body: IDocument, thunkAPI) => {
    try {
      const response = await axiosConfig.post<IDocument>(
        `/document/create`,
        body
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed create document"
      );
    }
  }
);
