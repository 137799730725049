import { Backgrounds, Button, NavigateBar } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/panding-page.png";
import Download from "../../assets/icons/download.png";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchSingleTimeline } from "../../store/api/fetchSingleTimeline";
import { useEffect, useState } from "react";
import { getTimelineIdToken } from "../../hooks/localstorage";
import styles from "./Panding.module.scss";
import Invited from "../../assets/background/white-background.png";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { addMemory } from "../../store/feature/panding/singlePandingMemory";

export const Panding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const id = getTimelineIdToken();
  const [name, setName] = useState<string>("");

  const { dataSingle, isLoad } = useAppSelector(
    (state) => state.singleTimelineReducer
  );
  console.log(dataSingle?.timeline?.participantsActions);
  useEffect(() => {
    dispatch(fetchSingleTimeline(id));
  }, [dispatch, id]);

  const getMemory = (item: any) => {
    dispatch(addMemory(item));
  };

  const getMemoryId = (id: string) => {
    // console.log(id)
  };

  return (
    <Layout>
      <NavigateBar />
      <Backgrounds background={Background} logo={Download}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            {isLoad ? (
              <div className={styles.loading}>
                <TailSpin
                  height="40"
                  width="40"
                  color="#000"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  visible={true}
                />
              </div>
            ) : (
              dataSingle?.timeline?.participantsActions?.map(
                (item: any, index) => (
                  <div
                    key={index}
                    className={styles.item}
                    onClick={() => {
                      getMemory(item);
                      getMemoryId(item?.memoryId);
                      setName(item?.name);
                    }}
                  >
                    <div className={styles.contentWrapper}>
                      <div className={styles.leftContent}>
                        <div className={styles.row}>
                          <p>Master headlines(CV) :</p>
                          <span>{item?.cv}</span>
                        </div>
                        <div className={styles.row}>
                          <p>Name :</p>
                          <span>{item?.name}</span>
                        </div>
                        <div className={styles.row}>
                          <p>Date :</p>
                          <span>{item?.date}</span>
                        </div>
                        <div className={styles.row}>
                          <p>Location :</p>
                          <span>{item?.location}</span>
                        </div>
                        <div className={styles.row}>
                          <p>Participants :</p>
                          <span>{item?.participants}</span>
                        </div>
                        <div
                          className={styles.row}
                          style={{
                            marginTop: "10px",
                            height: "60px",
                            overflowY: "auto",
                          }}
                        >
                          <p>Description :</p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          ></span>
                        </div>
                        <div className={styles.row}>
                          <div className={styles.circleWrapper}>
                            <b className={styles.circle}>{item?.privacy === "public" && <i>V</i>}</b>
                            <p className={styles.circleText}>Public</p>
                          </div>
                          <div className={styles.circleWrapper}>
                            <b className={styles.circle}>{item?.privacy === "private" && <i>V</i>}</b>
                            <p className={styles.circleText}>Private</p>
                          </div>
                        </div>
                        <div className={styles.row}>
                          <div className={styles.imageWrapper}>
                            {item?.memoryFiles.map((item: any) => (
                              <>
                                {item?.path?.includes("mp4") ||
                                item?.path?.includes("MP4") ||
                                item?.path?.includes("mov") ||
                                item?.path?.includes("MOV") ||
                                item?.path?.includes("mkv") ||
                                item?.path?.includes("MKV") ? (
                                  <video
                                    src={`https://storage.googleapis.com/bucket_for_h4m/${item?.path}`}
                                    controls={false}
                                  />
                                ) : (
                                  <img
                                    src={`https://storage.googleapis.com/bucket_for_h4m/${item?.path}`}
                                  />
                                )}
                              </>
                            ))}
                            {item?.bulkPageFiles.map((item: any) => (
                              <>
                                {item?.path?.includes("mp4") ||
                                item?.path?.includes("MP4") ||
                                item?.path?.includes("mov") ||
                                item?.path?.includes("MOV") ||
                                item?.path?.includes("mkv") ||
                                item?.path?.includes("MKV") ? (
                                  <video
                                    src={`https://storage.googleapis.com/bucket_for_h4m/${item?.path}`}
                                    controls={false}
                                  />
                                ) : (
                                  <img
                                    src={`https://storage.googleapis.com/bucket_for_h4m/${item?.path}`}
                                  />
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                        <div className={styles.row}>
                          <div className={styles.buttonWrapper}>
                            <button>Decline</button>
                            <button>Aprove</button>
                          </div>
                        </div>
                      </div>
                      <div className={styles.rightContent}>
                        <span>add media</span>
                      </div>
                    </div>
                    <img src={Invited} alt="Invited" />
                  </div>
                )
              )
            )}
          </div>
          <div className={styles.bottom}>
            <span>
              ({dataSingle?.timeline?.participantsActions?.length}) Pending
              memories
            </span>
            <Button
              title="View in Memory"
              action={() => navigate("/Panding-memory")}
            />
            <p>{`${name}`}</p>
          </div>
        </div>
      </Backgrounds>
    </Layout>
  );
};
