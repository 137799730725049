import styles from "./DifferentTimeLine.module.scss";
import { Backgrounds, NavigateBar } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import fill from "../../assets/background/basic.png";
import Download from "../../assets/icons/download.png";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useEffect, useState } from "react";
import { fetchUserTimelines } from "../../store/api/fetchUserTimelines";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arrow from "../../assets/icons/arrow-down.svg";
import { TailSpin } from "react-loader-spinner";
import { setTimelineIdToken } from "../../hooks/localstorage";
import { useNavigate } from "react-router-dom";

function CustomPrevArrow(props: any) {
  const { onClick } = props;
  return (
    <img
      className={styles.arrowLeft}
      onClick={onClick}
      src={arrow}
      alt="arrow"
    />
  );
}

function CustomNextArrow(props: any) {
  const { onClick } = props;

  return (
    <img
      className={styles.arrowRight}
      onClick={onClick}
      src={arrow}
      alt="arrow"
    />
  );
}

export const DifferentTimeLine = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector((state) => state.userTimelinesReducer);

  const settings = {
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: data.length > 2 ? 2 : 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    width: "100%",
  };

  useEffect(() => {
    dispatch(fetchUserTimelines());
  }, [dispatch]);

  const getTimeline = (id: string) => {
    setTimelineIdToken(id);
    if (id !== null) {
      navigate("/");
    }
  };

  return (
    <Layout>
      <NavigateBar />
      <Backgrounds background={Background} logo={Download}>
        <div className={styles.wrappers}>
          <div className={styles.containers}>
            {!isLoading ? (
              data &&
              data.length > 0 && (
                <Slider {...settings}>
                  {data?.map((item) => (
                    <div
                      onClick={() => getTimeline(item._id)}
                      key={item._id}
                      className={styles.slides}
                    >
                      {item?.mainPhoto !== "" ? (
                        <img
                          src={`https://storage.googleapis.com/bucket_for_h4m/${item?.mainPhoto}`}
                          alt="fill"
                        />
                      ) : (
                        <img src={fill} alt="fill" />
                      )}
                      <div className={styles.elements}>{item.name}</div>
                    </div>
                  ))}
                </Slider>
              )
            ) : (
              <TailSpin
                height="50"
                width="50"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
              />
            )}
          </div>
        </div>
      </Backgrounds>
    </Layout>
  );
};
