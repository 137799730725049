import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { ITimeline } from "./types";

export const fetchUpdateTimeline = createAsyncThunk(
  "updateTimeline/fetchUpdateTimeline",
  async ({ id, ...body }: { id: string | null } & ITimeline, thunkAPI) => {
    try {

      const response = await axiosConfig.post<ITimeline>(
        `timeline/update/${id}`,
        body,
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "timeline update failed"
      );
    }
  }
);
