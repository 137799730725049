import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IDeleleAction } from "./types";

export const fetchDeletePanding = createAsyncThunk(
  "deletePanding/fetchDeletePanding",
  async ({ id, ...body }: { id: string | null } & IDeleleAction, thunkAPI) => {
    try {
      const response = await axiosConfig.post<IDeleleAction>(
        `/timeline/declineParticipantAction/${id}`,
        body
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed delete memory"
      );
    }
  }
);
