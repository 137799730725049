import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCreateTimeline } from "../../../api/fetchCreateTimeline";

interface UserState {
  data: string;
  upload: boolean;
  isLoading: boolean;
  error: null;
}

const initialState: UserState = {
  data: "",
  upload: false,
  isLoading: false,
  error: null,
};

export const createTimelineReducer = createSlice({
  name: "createTimeline",
  initialState,
  reducers: {
    resetCreateTimelineData: (state) => {
      state.data = initialState.data;
    },
    Upload: (state, action: PayloadAction<any>) => {
      state.upload = action.payload;
    },
    resetUpload: (state) => {
      state.upload = initialState.upload;
    },
  },
  extraReducers: {
    [fetchCreateTimeline.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload;
    },
    [fetchCreateTimeline.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchCreateTimeline.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { resetCreateTimelineData, Upload, resetUpload } = createTimelineReducer.actions;

export default createTimelineReducer.reducer;
