import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IFiles } from "./IFiles";

export const fetchFilesNonAuth = createAsyncThunk(
  "filesNonAuth/fetchFilesNonAuth",
  async (body: IFiles, thunkAPI) => {
    try {
      const response = await axiosConfig.get<string>(`/timeline/${body?.timelineSingle}/files/${body?.names}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed"
      );
    }
  }
);
