import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  FieldDescription,
  SmallRadio,
  TextFieldLine,
} from "../../../../components";
import styles from "./DocumentContainer.module.scss";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { Carousel } from "../Carousel";
import { MultiUpload } from "../MultiUpload";
import { fetchCreateDocument } from "../../../../store/api/fetchCreateDocument";
import { getTimelineIdToken } from "../../../../hooks/localstorage";
import { fetchDocumentUpdateFiles } from "../../../../store/api/fetchDocumentUpdateFiles";
import { resetCreateDocumentData } from "../../../../store/feature/document/createDocument";
import { resetUpdateDocumentFiles } from "../../../../store/feature/document/updateFilesDocument";

interface IEditContainer {
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
}

export const DocumentContainer: FC<IEditContainer> = ({
  setOpenPopup,
  setGetPhoto,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const timeline = getTimelineIdToken();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [files, setFiles] = useState<any>([]);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const { data, isLoading, error } = useAppSelector(
    (state) => state.createDocumentReducer
  );
  const { responseData, Load, err } = useAppSelector(
    (state) => state.updateFilesDocumentReducer
  );

  const handlerCreate = () => {
    dispatch(fetchCreateDocument({ name, description, privacy, timeline }));
  };

  useEffect(() => {
    if (data.id !== null && files.length <= 0) {
      dispatch(resetCreateDocumentData());
      navigate("/Document");
    } else if (data.id !== null && files.length > 0 && !isRequestSent) {
      const formData: any = new FormData();
      files.forEach((file: any) => {
        formData.append("files", file);
      });
      formData.append("timeline", timeline || "");
      dispatch(fetchDocumentUpdateFiles({ formData, ids: data.id || "" }));
      setIsRequestSent(true);
    } else if (responseData === "OK" && isRequestSent) {
      dispatch(resetCreateDocumentData());
      dispatch(resetUpdateDocumentFiles());
      navigate("/Document");
    }
  }, [
    data.id,
    responseData,
    files,
    isRequestSent,
    navigate,
    dispatch,
    timeline,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        {isLoading && (
          <TailSpin
            height="50"
            width="50"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        )}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.texts}>
          <div className={styles.column}>
            <TextFieldLine
              label="Name :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
            <FieldDescription
              label="Description :"
              value={description}
              setValue={setDescription}
              sx={{ width: "300px" }}
            />
          </div>
          <div className={styles.radio}>
            <SmallRadio setPrivacy={setPrivacy} privacy={privacy} />
          </div>
          <div className={styles.carousel}>
            <p>Additional media :</p>
            <Carousel
              setOpenPopup={setOpenPopup}
              setGetPhoto={setGetPhoto}
              picture={files}
              setPicture={setFiles}
            />
          </div>
        </div>
        <div className={styles.redirect}>
          <MultiUpload image={files} setImage={setFiles} />
        </div>
      </div>
      <div>
        <p className={styles.error}>{error || err}</p>
        <Button
          sx={{ display: "flex", justifyContent: "right" }}
          title="Create"
          action={handlerCreate}
        />
      </div>
    </div>
  );
};
