import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDocumentUpdateFiles } from "../../../api/fetchDocumentUpdateFiles";

interface UserState {
  responseData: string;
  Load: boolean;
  err: null;
}

const initialState: UserState = {
  responseData: "",
  Load: false,
  err: null,
};

export const updateFilesDocumentReducer = createSlice({
  name: "updateFilesDocument",
  initialState,
  reducers: {
    resetUpdateDocumentFiles: (state) => {
      state.responseData = initialState.responseData;
    },
  },
  extraReducers: {
    [fetchDocumentUpdateFiles.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.Load = false;
      state.err = null;
      state.responseData = action.payload;
    },
    [fetchDocumentUpdateFiles.pending.type]: (state) => {
      state.Load = true;
    },
    [fetchDocumentUpdateFiles.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.Load = false;
      state.err = action.payload;
    },
  },
});

export const { resetUpdateDocumentFiles } = updateFilesDocumentReducer.actions;

export default updateFilesDocumentReducer.reducer;
