import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMemory } from "./types";
import { fetchSingleMemory } from "../../../api/fetchSingleMemory";

interface UserState {
  data: IMemory | null;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: null,
  isLoading: false,
  error: "",
};

export const singleMemoryReducer = createSlice({
  name: "singleMemory",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSingleMemory.fulfilled.type]: (state, action: PayloadAction<IMemory>) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchSingleMemory.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchSingleMemory.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default singleMemoryReducer.reducer;
