import { Select } from "antd";
import styles from "./CategoryAdmin.module.scss";
import { FC } from "react";

interface ICategoryAdmin {
  admin: any;
  setAdmin: any;
  participants?: any;
}

export const CategoryAdmin: FC<ICategoryAdmin> = ({
  admin,
  setAdmin,
  participants,
}) => {
  const handleChange = (value: string) => {
    setAdmin(value);
  };

  return (
    <div
      className={styles.wrapper}
      style={{
        display: "grid",
      }}
    >
      <label>Add admin:</label>
      <Select
        onChange={handleChange}
        options={participants.map((p: any) => ({
          label: p.name,
          value: p.name,
        }))}
        value={admin}
        className="my-select"
        style={{
          width: "100%",
        }}
      />
    </div>
  );
};
