import styles from "./NonAuthBar.module.scss";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "../..";
import { TailSpin } from "react-loader-spinner";
import { fetchSignUp } from "../../../store/api/fetchSignUp";
import { fetchGetParticipantData } from "../../../store/api/fetchGetParticipantData";
import { getNameToken, getTimelineIdToken } from "../../../hooks/localstorage";

interface INonAuthBar {
  dataParticipant?: any;
}

export const NonAuthBar: FC<INonAuthBar> = ({ dataParticipant }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const author = getNameToken();
  const id = getTimelineIdToken();
  const { data, isLoading, error } = useAppSelector(
    (state) => state.authReducer
  );

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handlerClick = () => {
    dispatch(fetchSignUp({ name, email, phone, password, timeline: id }));
  };

  useEffect(() => {
    dispatch(fetchGetParticipantData({ id, author }));
  }, [dispatch]);

  useEffect(() => {
    const token = sessionStorage.getItem("JWT_TOKEN");
    if (token) {
      navigate("/");
    }
  }, [data, error]);

  useEffect(() => {
    if (dataParticipant?.participant) {
      const { name, email, phone } = dataParticipant?.participant;
      setName(name);
      setEmail(email);
      setPhone(phone);
    }
  }, [dataParticipant?.participant]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <div className={styles.burgerLine}></div>
        <div className={styles.burgerLine}></div>
        <div className={styles.burgerLine}></div>
      </div>
      <div className={styles.texts}>
        <p> Thank you for helping to Fill the missing pieces In Memory.</p>
        <p>
          To enter the full album, kindly sign up <span>Become a New User</span>
        </p>
      </div>
      <div className={styles.body}>
        <TextField
          setValue={setName}
          value={name}
          type="text"
          label="Name:"
          sx={{ width: "80%" }}
        />
        <TextField
          setValue={setEmail}
          value={email}
          type="text"
          label="Email:"
          sx={{ marginTop: "30px", width: "80%" }}
        />
        <TextField
          setValue={setPhone}
          value={phone}
          type="text"
          label="Phone:"
          useMask={true}
          sx={{ marginTop: "30px", width: "80%" }}
        />
        <TextField
          setValue={setPassword}
          value={password}
          type="password"
          label="Password:"
          sx={{ marginTop: "30px", width: "80%" }}
        />
        <div className={styles.error}>
          <span>{error && error}</span>
        </div>
      </div>
      <div className={styles.footer}>
        {isLoading ? (
          <div className={styles.loader}>
            <TailSpin
              height="30"
              width="30"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              visible={true}
            />
          </div>
        ) : (
          <Button action={handlerClick} title="Sign up" />
        )}
      </div>
    </div>
  );
};
