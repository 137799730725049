import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCreateDocument } from "../../../api/fetchCreateDocument";

interface UserState {
  data: {
    id: null;
  };
  isLoading: boolean;
  error: string;
}

export interface IResult {
  id: null;
}

const initialState: UserState = {
  data: {
    id: null,
  },
  isLoading: false,
  error: "",
};

export const createDocumentReducer = createSlice({
  name: "createDocument",
  initialState,
  reducers: {
    resetCreateDocumentData: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [fetchCreateDocument.fulfilled.type]: (
      state,
      action: PayloadAction<IResult>
    ) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchCreateDocument.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchCreateDocument.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { resetCreateDocumentData } = createDocumentReducer.actions;

export default createDocumentReducer.reducer;
