import { FC, useEffect, useState } from "react";
import styles from "./PopupTimeline.module.scss";
import { Button, TextFieldLine } from "../../../../components";
import Minus from "../../../../assets/icons/minus.png"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { getTimelineIdToken } from "../../../../hooks/localstorage";
import { fetchUpdateParticipant } from "../../../../store/api/fetchUpdateParticipant";
import { SmallRadioParticipants } from "../SmallRadioParticipants";
import { resetUpdateParticipant } from "../../../../store/feature/participants/updateParticipant";

interface IPopupTimeline {
  users: any;
  setOpenPopup: (value: boolean) => void;
}

export const PopupTimeline: FC<IPopupTimeline> = ({ users, setOpenPopup }) => {
  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useAppSelector((state) => state.updateParticipantReducer);
  
  const id = getTimelineIdToken();
  
  const [name, setName] = useState<string>("");
  const [previosEmail, setPreviosEmail] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [role, setRole] = useState<string>("");

  useEffect(() => {
    if (users) {
      const { name, email, phone, role } = users;
      setName(name);
      setEmail(email);
      setPhone(phone);
      setRole(role);
      setPreviosEmail(email)
    }
  }, [users]);

  useEffect(() => {
    dispatch(resetUpdateParticipant());
  }, [dispatch]);

  const sendUpdate = () => {
    dispatch(fetchUpdateParticipant({name, email, phone, role, id, previosEmail}));
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img className={styles.close} onClick={() => setOpenPopup(false)} src={Minus} alt="Minus" />
        <div className={styles.head}>
          <p>Do you want to change subscribers?</p>
          <h1>Participants</h1>
        </div>
        <div className={styles.column}>
          <TextFieldLine
            label="Add name :"
            type="text"
            value={name}
            setValue={setName}
            sx={{ width: "300px" }}
          />
          <TextFieldLine
            label="Email :"
            type="text"
            value={email}
            setValue={setEmail}
            sx={{ width: "300px" }}
          />
          <TextFieldLine
            label="Phone number :"
            type="text"
            value={phone}
            setValue={setPhone}
            sx={{ width: "300px" }}
            useMask={true}
          />
        </div>

        <div className={styles.messages}>
          {error ? (
            <p className={styles.error}>{error}</p>
          ) : data === "OK" ? (
            <p className={styles.done}>
              Participation has been changed
            </p>
          ) : null}
        </div>

        <SmallRadioParticipants
          setValue={setRole}
          value={role}
          sx={{ marginTop: "40px" }}
        />
        <div style={{ display: "flex" }}>
          <Button   sx={{ marginLeft: "auto" }} title="Save" action={sendUpdate} />
        </div>
      </div>
    </div>
  );
};
